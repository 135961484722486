export default class Knicknack {
    id = 0;
    name = '';
    cost = 0;
    description = '';
    consumable = false;

    constructor ({
        id = 0,
        name = '',
        cost = 0,
        description = '',
        consumable = false
    }) {
        this.id = id;
        this.name = name;
        this.cost = cost;
        this.description = description;
        this.consumable = consumable;
    }
}
