import Storage from '../store/Storage';
import EventEmitter from '../models/EventEmitter.js';
import Character from '../models/Character';
import Animal from '../models/Animal';
import Gang from '../models/Gang';
import RivalGang from '../models/RivalGang.js';
import { getCharacterStats } from '../StatCalculator.js';

const appVersion = '1.0';

const emitter = new EventEmitter();

/**
 * Return UTC datetime string for right now
 * @return {String}
 */
const currentTimestamp = function () {
    const d = new Date();
    return d.toUTCString();
};

/**
 * Set a gang model
 * @param {Object} gang_obj
 * @returns Gang|RivalGang
 */
const setGangModel = function (gang_obj) {
    if (gang_obj.rival) {
        return new RivalGang(gang_obj);
    }
    return new Gang(gang_obj);
};

/**
 * Get a single gang model.
 * @param {String} uuid Gang's uuid.
 * @returns {Gang|RivalGang|null}
 */
const getGang = function (uuid) {
    if (!uuid) {
        return null;
    }
    const gang_obj = Storage.get(uuid);
    if (!gang_obj || !gang_obj.uuid) {
        return null;
    }
    return setGangModel(gang_obj);
};
/**
 * Save a single gang.
 * @param {Gang} gang
 * @returns {Boolean}
 */
const saveGang = function (gang) {
    // set any new characters to not new
    gang.characters.forEach((char) => {
        if (char.isNew) {
            char.isNew = false;
        }
    });
    gang.animals.forEach((animal) => {
        if (animal.isNew) {
            animal.isNew = false;
        }
    });

    // Update saved timestamp
    gang.updated = currentTimestamp();
    gang.version = appVersion;
    Storage.set(gang.uuid, gang);
    emitter.trigger('gang:edit');
};
/**
 * Remove a gang from the store.
 * @param {String} uuid
 */
const removeGangLocal = function (uuid) {
    Storage.remove(uuid);
    emitter.trigger('gang:remove', {
        uuid
    });
};
/**
 * Get all gangs saved locally.
 * @returns {Gang[]}
 */
const getAllGangsLocal = function () {
    const gangs = [];
    Storage.getAll().forEach((gang_obj) => {
        const model = setGangModel(gang_obj);
        gangs.push(model);
    });
    return gangs;
};
/**
 * Import/save a gang from an obj and return the model.
 * @param {Object} gang_obj Gang data from backup (we hope).
 * @returns {Gang}
 * @throws {Error}
 */
const importGang = function (gang_obj) {
    if (typeof gang_obj !== 'object' || !gang_obj.uuid) {
        throw new Error(`Data appears to be invalid. Try removing any text that isn't part of the backup (i.e. email introduction).`);
    }
    const newGang = setGangModel(gang_obj);
    // do we have this char uuid already
    const existingGang = getGang(gang_obj.uuid);
    if (existingGang && existingGang.name !== '' && existingGang.name !== newGang.name) {
        // existing uuid but different name
        if (!newGang.uuid_prev) {
            newGang.uuid_prev = newGang.uuid;
            newGang.uuid = crypto.randomUUID();
        } else {
            const temp_uuid = newGang.uuid_prev;
            newGang.uuid_prev = newGang.uuid;
            newGang.uuid = temp_uuid;
        }
    }
    saveGang(newGang);
    return newGang;
};
/**
 * Set prefix for LocalStorage keys.
 * @param {String} prefix
 */
const setLocalStoragePrefix = function (prefix) {
    if (!prefix) {
        throw Error('LocalStorage prefix is empty.');
    }
    Storage.setPrefix(prefix);
};

/**
 * @param {Gang} gang
 * @returns {Character}
 */
const addCharacter = function (gang) {
    if (gang.rival) {
        return;
    }
    const id = gang.getNextCharacterId();
    const character = new Character({ id });
    if (!gang.draft) {
        character.isNew = true;
    }
    gang.characters.push(character);
    return character;
};

const validateCharacter = function (char) {
    const errors = [];
    if (!char.name) {
        errors.push('Characters must have names.');
    }
    if (char.profession <= 0) {
        errors.push(`${char.name} must have a Profession.`);
    }
    const stats = getCharacterStats(char);
    Object.keys(stats).forEach((att) => {
        if (stats[att] > 6) {
            errors.push(`${char.name} cannot have ${att} > 6.`);
        }
    });
    return errors;
};

const validateGang = function (gang, newCost) {
    if (gang.rival) {
        return;
    }
    let errors = [];
    if (!gang.name) {
        errors.push('Gang must have a name.');
    }
    gang.characters.forEach((char) => {
        errors = errors.concat(validateCharacter(char));
    });
    if ((gang.cash - newCost) < 0) {
        errors.push('Cost cannot be greater than cash.');
    }
    if (errors.length > 0) {
        throw new Error(errors.join('<br/>'));
    }
};

/**
 * @param {Gang} gang
 * @returns {Animal}
 */
const addAnimal = function (gang) {
    const id = gang.getNextAnimalId();
    const animal = new Animal({ id });
    if (!gang.draft) {
        animal.isNew = true;
    }
    gang.animals.push(animal);
    return animal;
};

/**
 * Get gang data for export.
 * @param {String[]} uuids
 * @returns {Gang[]}
 */
const exportGangs = function (uuids) {
    const data = [];
    uuids.forEach((uuid) => {
        data.push(getGang(uuid));
    });
    return data;
};

/**
 * JSON data from the file or text
 * @param {String} data JSON
 */
const importGangs = function (data) {
    try {
        // look for the start of the JSON string Array of Objects
        let start = data.indexOf('[{');
        let end = data.lastIndexOf('}]');
        // make sure it's not :[{, an array of objects inside one of the objects
        const check = data.indexOf(':[{');
        if (check !== -1 && check < start) {
            // if so start over
            start = -1;
        }
        if (start === -1) {
            start = data.indexOf('{');
            end = data.lastIndexOf('}');
            data = data.substring(start);
            data = data.substring(0, end + 1);
        } else {
            data = data.substring(start);
            data = data.substring(0, end + 2);
        }
        data = data.trim(); // just in case

        // convert linebreaks to html br else JSON.parse breaks
        // first make sure it's not a break between objects...
        data = data.replace(/\},[\r\n]+\{/g, '},{');
        data = data.replace(/(?:\r\n|\r|\n)/g, '<br/>');
        let backups = JSON.parse(data);
        // make it an array
        if (!Array.isArray(backups)) {
            backups = [backups];
        }

        backups.forEach((gang_obj) => {
            if (typeof gang_obj !== 'object' || !gang_obj.uuid) {
                throw new Error(`Data appears to be invalid. Try removing any text that isn't part of the backup (i.e. email introduction).`);
            }
            const newGang = setGangModel(gang_obj);
            // do we have this gang key already
            const existingGang = getGang(gang_obj.uuid);
            if (existingGang && existingGang.name !== '' && existingGang.name !== newGang.name) {
                // existing key but different name
                alert(`You have a gang with that unique id but a different name then "${newGang.name}". Import aborted. Edit the uuid in the import or rename/delete the existing Gang`);
                return;
            }
            saveGang(newGang);
            alert(`Imports: "${newGang.name}"`);
        });
        emitter.trigger('gang:edit');
    } catch (e) {
        alert(`Error processing backup data: ${e.message}`);
    }
};

export {
    emitter,
    getGang,
    saveGang,
    validateGang,
    removeGangLocal,
    getAllGangsLocal,
    importGang,
    setLocalStoragePrefix,
    addCharacter,
    addAnimal,
    exportGangs,
    importGangs
};
