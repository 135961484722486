import { html, css } from 'lit';
import BaseElement from './BaseElement.js';
import { emitter } from '../services/GangService.js';

export default class FlawDetails extends BaseElement {
    static styles = [
        super.styles,
        css`
    :host([selected]) li.list-group-item {
        background-color: lightgoldenrodyellow;
        border-width: 3px;
    }
    `
    ];

    static properties = {
        selected: { type: Boolean, reflect: true }
    };

    constructor ({
        flaw,
        charId = 0,
        selected = false
    }) {
        super();
        this.flaw = flaw;
        this.charId = charId;
        this.selected = selected;
        this.dataset.id = this.flaw.id;
    }

    handleSelect (ev) {
        if (this.selected) {
            return;
        }
        this.selected = true;
        emitter.trigger('character:flaw:add', { flawId: this.flaw.id, charId: this.charId });
    }

    render () {
        return html`<li class="list-group-item" @click=${this.handleSelect}>
            <h4>${this.flaw.name}</h4>
            <dl class="attributes">
                ${this.flaw.attributes.iron ? html`<dt>Iron</dt><dd>${this.flaw.attributes.iron}</dd>` : ''}
                ${this.flaw.attributes.brawl ? html`<dt>Brawl</dt><dd>${this.flaw.attributes.brawl}</dd>` : ''}
                ${this.flaw.attributes.grit ? html`<dt>Grit</dt><dd>${this.flaw.attributes.grit}</dd>` : ''}
                ${this.flaw.attributes.luck ? html`<dt>Luck</dt><dd>${this.flaw.attributes.luck}</dd>` : ''}
            </dl>
            ${this.flaw.benefit ? html`<div><strong>Benefit:</strong> ${this.flaw.benefit}</div>` : ''}
            ${this.flaw.tradeoff ? html`<div><strong>Trade-off:</strong> ${this.flaw.tradeoff}</div>` : ''}
        </li>`;
    }
}

if (!window.customElements.get('dbl-flaw-details')) {
    window.customElements.define('dbl-flaw-details', FlawDetails);
}
