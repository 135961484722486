import { html, css } from 'lit';
import { emitter } from '../services/GangService.js';
import { getProfession } from '../services/ProfessionService.js';
import ProfessionList from './ProfessionList.js';
import EquipmentList from './EquipmentList.js';
import KnicknackList from './KnicknackList.js';
import WeaponList from './WeaponList.js';
import UpgradeList from './UpgradeList.js';
import FlawList from './FlawList.js';
import NotorietyList from './NotorietyList.js';
import { calcCharacterCost, calcNewEquipmentCost, calcNewWeaponCost } from '../CostCalculator.js';
import { getCharacterStats } from '../StatCalculator.js';
import BaseElement from './BaseElement.js';
import { getWeapon, getWeaponCost } from '../services/WeaponService.js';
import { getUpgrade } from '../services/UpgradeService.js';
import { getFlaw } from '../services/FlawService.js';
import { getEquipment, getEquipmentCost } from '../services/EquipmentService.js';
import { getKnicknack, getKnicknackCost } from '../services/KnicknackService.js';
import Modal from './Modal.js';
import { getTrait } from '../services/NotorietyService.js';
import { getName } from '../services/NameService.js';

export default class CharacterEdit extends BaseElement {
    static styles = [
        super.styles,
        css``
    ];

    /** @prop {Character} */
    character = null;
    constructor ({
        character,
        gang
    }) {
        super();
        this.character = character;
        this.gang = gang;

        this.dataset.id = this.character.id;

        this.profHandler = this.#handleProfUpdate.bind(this);
        this.weaponHandler = this.#handleWeaponAdd.bind(this);
        this.flawHandler = this.#handleFlawAdd.bind(this);
        this.upgradeHandler = this.#handleUpgradeAdd.bind(this);
        this.equipmentHandler = this.#handleEquipmentAdd.bind(this);
        this.knicknackHandler = this.#handleKnicknackAdd.bind(this);
        this.notorietyHandler = this.#handleNotorietyAdd.bind(this);
    }

    connectedCallback () {
        super.connectedCallback();
        emitter.on('character:profession:update', this.profHandler);
        emitter.on('character:weapon:add', this.weaponHandler);
        emitter.on('character:upgrade:add', this.upgradeHandler);
        emitter.on('character:flaw:add', this.flawHandler);
        emitter.on('character:equipment:add', this.equipmentHandler);
        emitter.on('character:knicknack:add', this.knicknackHandler);
        emitter.on('character:notoriety:update', this.notorietyHandler);
        emitter.on('gang:edit', () => {
            this.requestUpdate();
        });
    }

    disconnectedCallback () {
        super.disconnectedCallback();
        emitter.off('character:profession:update', this.profHandler);
        emitter.off('character:weapon:add', this.weaponHandler);
        emitter.off('character:upgrade:add', this.upgradeHandler);
        emitter.off('character:flaw:add', this.flawHandler);
        emitter.off('character:equipment:add', this.equipmentHandler);
        emitter.off('character:knicknack:add', this.knicknackHandler);
        emitter.off('character:notoriety:update', this.notorietyHandler);
    }

    // For existing characters (buy/sell weapons/equipment)
    updatePendingCost (cost) {
        if (this.gang.draft || this.character.isNew) {
            return;
        }
        emitter.trigger('cost:update', { cost });
    }

    #triggerCharacterUpdate () {
        emitter.trigger('character:update', { id: this.character.id, isNew: this.character.isNew });
    }

    // new/draft characters only
    #handleProfUpdate ({ profId, charId }) {
        if (charId !== this.character.id) {
            return;
        }
        this.character.profession = profId;
        if (profId === 7) { // farmhands
            this.character.upgrades = [];
            this.character.flaws = [];
        }
        this.#triggerCharacterUpdate();
        this.requestUpdate();
    }

    #handleWeaponAdd ({ weaponId, charId }) {
        if (charId !== this.character.id) {
            return;
        }
        this.character.weapons.push(weaponId);
        this.#triggerCharacterUpdate();
        if (!this.gang.draft && !this.character.isNew) {
            const cost = calcNewWeaponCost(weaponId, this.gang);
            this.updatePendingCost(cost);
        }
        this.requestUpdate();
    }

    // new/draft characters only
    #handleUpgradeAdd ({ upgradeId, charId }) {
        if (charId !== this.character.id) {
            return;
        }
        this.character.upgrades.push(upgradeId);
        this.#triggerCharacterUpdate();
        this.requestUpdate();
    }

    // new/draft characters only
    #handleFlawAdd ({ flawId, charId }) {
        if (charId !== this.character.id) {
            return;
        }
        this.character.flaws.push(flawId);
        this.#triggerCharacterUpdate();
        this.requestUpdate();
    }

    #handleEquipmentAdd ({ equipmentId, charId }) {
        if (charId !== this.character.id) {
            return;
        }
        this.character.equipment.push(equipmentId);
        this.#triggerCharacterUpdate();
        if (!this.gang.draft && !this.character.isNew) {
            const cost = calcNewEquipmentCost(equipmentId, this.gang);
            this.updatePendingCost(cost);
        }
        this.requestUpdate();
    }

    #handleKnicknackAdd ({ knicknackId, charId }) {
        if (charId !== this.character.id) {
            return;
        }
        this.character.knicknacks.push(knicknackId);
        this.#triggerCharacterUpdate();
        this.requestUpdate();
    }

    #handleNotorietyAdd ({ traitId = 0, charId = 0, level = 0 }) {
        if (traitId <= 0 || charId !== this.character.id || level === 0) {
            return;
        }
        this.character.notorietyTraits[level] = traitId;
        this.#triggerCharacterUpdate();
        this.requestUpdate();
    }

    saveName (ev) {
        this.character.name = ev.target.value;
        this.#triggerCharacterUpdate();
    }

    saveNotoriety (ev) {
        this.character.notoriety = ev.target.value;
        this.#triggerCharacterUpdate();
        this.requestUpdate();
    }

    saveHanded (ev) {
        this.character.handed = ev.target.value;
        this.#triggerCharacterUpdate();
    }

    close () {
        document.querySelector('dbl-gang-page')?.clearColumns(true, true);
    }

    showProfessions (ev) {
        ev.preventDefault();
        document.querySelector('dbl-gang-page')?.fillColumn(
            new ProfessionList({ charId: this.character.id, profId: this.character.profession, isDraft: this.gang.draft }),
            3,
            'Professions'
        );
    }

    showWeapons (ev) {
        ev.preventDefault();
        document.querySelector('dbl-gang-page')?.fillColumn(
            new WeaponList({ charId: this.character.id }),
            3,
            'Weapons'
        );
    }

    #removeWeapon ({ target }) {
        const id = Number(target.dataset.id || 0);
        const i = this.character.weapons.findIndex((w) => w === id);
        if (i > -1) {
            this.character.weapons.splice(i, 1);
            this.#triggerCharacterUpdate();
            if (!this.gang.draft && !this.character.isNew) {
                const sale = Math.round(getWeaponCost(id) / 2);
                this.updatePendingCost(-sale);
            }

            this.requestUpdate();
        }
    }

    showUpgrades (ev) {
        ev.preventDefault();
        document.querySelector('dbl-gang-page')?.fillColumn(
            new UpgradeList({ character: this.character }),
            3,
            'Upgrades'
        );
    }

    #removeUpgrade ({ target }) {
        const id = Number(target.dataset.id || 0);
        const i = this.character.upgrades.findIndex((w) => w === id);
        if (i > -1) {
            this.character.upgrades.splice(i, 1);
            emitter.trigger('upgrade:deselect', { id });
            this.#triggerCharacterUpdate();
            this.requestUpdate();
        }
    }

    showFlaws (ev) {
        ev.preventDefault();
        document.querySelector('dbl-gang-page')?.fillColumn(
            new FlawList({ character: this.character }),
            3,
            'Flaws'
        );
    }

    #removeFlaw ({ target }) {
        const id = Number(target.dataset.id || 0);
        const i = this.character.flaws.findIndex((w) => w === id);
        if (i > -1) {
            this.character.flaws.splice(i, 1);
            emitter.trigger('flaw:deselect', { id });
            this.#triggerCharacterUpdate();
            this.requestUpdate();
        }
    }

    showEquipment (ev) {
        ev.preventDefault();
        document.querySelector('dbl-gang-page')?.fillColumn(
            new EquipmentList({ charId: this.character.id }),
            3,
            'Equipment'
        );
    }

    showKnicknacks (ev) {
        ev.preventDefault();
        document.querySelector('dbl-gang-page')?.fillColumn(
            new KnicknackList({ charId: this.character.id }),
            3,
            'Knicknacks'
        );
    }

    #removeEquipment ({ target }) {
        const id = Number(target.dataset.id || 0);
        const sell = target.dataset?.sell === '1';
        const i = this.character.equipment.findIndex((w) => w === id);
        if (i > -1) {
            this.character.equipment.splice(i, 1);
            this.#triggerCharacterUpdate();
            if (!this.gang.draft && !this.character.isNew && sell) {
                const sale = Math.round(getEquipmentCost(id) / 2);
                this.updatePendingCost(-sale);
            }
            this.requestUpdate();
        }
    }

    #removeKnicknack ({ target }) {
        const id = Number(target.dataset.id || 0);
        const sell = target.dataset?.sell === '1';
        const i = this.character.knicknacks.findIndex((w) => w === id);
        if (i > -1) {
            this.character.knicknacks.splice(i, 1);
            this.#triggerCharacterUpdate();
            if (sell) {
                const sale = getKnicknackCost(id);
                this.updatePendingCost(-sale);
            }
            this.requestUpdate();
        }
    }

    #showNotoriety (ev) {
        ev.preventDefault();
        const level = Number(ev.target.dataset?.level || 0);
        document.querySelector('dbl-gang-page')?.fillColumn(
            new NotorietyList({ charId: this.character.id, level, traitId: this.character.trait }),
            3,
            'Traits'
        );
    }

    #attrHelp (ev) {
        const helpModal = new Modal();
        helpModal.hidden = true;
        helpModal.header = 'Attributes';
        helpModal.content = `<div>
        <p><strong>Iron</strong> is added to ranged attack rolls.</p>
        <p><strong>Brawl</strong> is added to melee attack rolls.</p>
        <p><strong>Grit</strong> is how many wounds you can take.</p>
        <p><strong>Luck</strong> is a consumable resource for saving against wounds.</p>
        </div>
    `;

        document.body.append(helpModal);
        helpModal.open();
    }

    _attributeList () {
        const attributes = getCharacterStats(this.character);
        let alert = '';
        Object.keys(attributes).forEach((k) => {
            if (attributes[k] > 6) {
                alert = html`<p class="alert alert-danger">Attributes cannot be more than 6.</p>`;
            }
        });
        return html`<div class="d-flex justify-content-between align-items-center mb-3">
        <dl class="attributes">
        <dt>Iron</dt>
        <dd>${attributes.iron}</dd>
        <dt>Brawl</dt>
        <dd>${attributes.brawl}</dd>
        <dt>Grit</dt>
        <dd>${attributes.grit}</dd>
        <dt>Luck</dt>
        <dd>${attributes.luck}</dd>
    </dl>
    <button type="button" class="btn btn-secondary btn-sm" @click=${this.#attrHelp}>?</button>
    </div>
    ${alert}`;
    }

    #weaponList (weaponId) {
        const weapon = getWeapon(weaponId);
        const sellButton = !this.gang.draft && !this.character.isNew
            ? html`<button type="button" class="btn btn-secondary btn-sm" data-sell=1 data-id="${weaponId}" @click=${this.#removeWeapon}>Sell</button>`
            : '';
        return html`<li>${weapon.name} $${weapon.cost} <button type="button" class="btn btn-secondary btn-sm" data-id="${weaponId}" @click=${this.#removeWeapon}>X</button> ${sellButton}</li>`;
    }

    #upgradeList (upgradeId) {
        const allowRemove = this.gang.draft || this.character.isNew;
        const upgrade = getUpgrade(upgradeId);
        return html`<li>${upgrade.name} $${upgrade.cost} ${allowRemove ? html`<button type="button" class="btn btn-secondary btn-sm" data-id="${upgradeId}" @click=${this.#removeUpgrade}>X</button>` : ''}</li>`;
    }

    #flawList (flawId) {
        const allowRemove = this.gang.draft || this.character.isNew;
        const flaw = getFlaw(flawId);
        return html`<li>${flaw.name} ${allowRemove ? html`<button type="button" class="btn btn-secondary btn-sm" data-id="${flawId}" @click=${this.#removeFlaw}>X</button>` : ''}</li>`;
    }

    #equipmentList (equipmentId) {
        const equipment = getEquipment(equipmentId);
        const sellButton = !this.gang.draft && !this.character.isNew
            ? html`<button type="button" class="btn btn-secondary btn-sm" data-sell=1 data-id="${equipmentId}" @click=${this.#removeEquipment}>Sell</button>`
            : '';
        return html`<li>${equipment.name} $${equipment.cost} <button type="button" class="btn btn-secondary btn-sm" data-id="${equipmentId}" @click=${this.#removeEquipment}>X</button> ${sellButton}</li>`;
    }

    #knicknackList (knicknackId) {
        const knicknack = getKnicknack(knicknackId);
        return html`<li>${knicknack.name} $${knicknack.cost} <button type="button" class="btn btn-secondary btn-sm" data-id="${knicknackId}" @click=${this.#removeKnicknack}>X</button> <button type="button" class="btn btn-secondary btn-sm" data-sell=1 data-id="${knicknackId}" @click=${this.#removeKnicknack}>Sell</button></li>`;
    }

    #notorietyTraits () {
        if (this.character.notoriety < 5) {
            return '';
        }
        const trait5 = getTrait(this.character.notorietyTraits[5] || 0);
        const trait10 = getTrait(this.character.notorietyTraits[10] || 0);
        const trait15 = getTrait(this.character.notorietyTraits[15] || 0);
        return html`<div><h3>Notoriety Traits</h3><ul>
        <li>5: ${trait5 ? `${trait5.name}` : html`<button type="button" class="btn btn-secondary btn-sm" data-level=5 @click=${this.#showNotoriety}>Pick Notoriety Trait</button>`}</li>
        ${this.character.notoriety >= 10 ? html`<li>10: ${trait10 ? `${trait10.name}` : html`<button type="button" class="btn btn-secondary btn-sm" data-level=10 @click=${this.#showNotoriety}>Pick Notoriety Trait</button>`}</li>` : ''}
        ${this.character.notoriety >= 15 ? html`<li>15: ${trait15 ? `${trait15.name}` : html`<button type="button" class="btn btn-secondary btn-sm" data-level=15 @click=${this.#showNotoriety}>Pick Notoriety Trait</button>`}</li>` : ''}
        </ul></div>`;
    }

    #createName () {
        const name = getName();
        this.renderRoot.querySelector('input[name="c-name"]').value = name;
    }

    render () {
        const allowFullEdit = (this.gang.draft || this.character.isNew);
        const isFarmhand = this.character.profession === 7;
        const profession = getProfession(this.character.profession) || {};
        return html`<div class="d-flex justify-content-between align-items-center mb-3">
            <h2>Edit Character</h2>
            <button type="button" class="btn btn-secondary btn-sm" @click=${this.close}>Close</button>
        </div>
        ${this.character.isNew ? html`<div class="alert alert-info">This character is a new hire to the gang. Once you hit "Save" on the Gang edit view, you will no longer be able to adjust this character's upgrades or flaws.</div>` : ''}
        <div class="d-flex justify-content-between align-items-center mb-3">
                ${this.gang.draft || this.character.isNew ? html`<div><strong>Total Cost</strong> $${calcCharacterCost(this.character, this.gang)}</div>` : ''}
                <div><strong>Notoriety</strong> <input style="display:inline-block;width:auto;" type="number" id="c-notoriety" name="c-notoriety" class="form-control" size=3 value="${this.character.notoriety}" @change=${this.saveNotoriety} min=0 /></div>
            </div>

            <div class="row mb-3 align-items-center">
                <label for="c-name" class="col-sm-3 col-form-label">Name</label>
                <div class="input-group col-sm-9">
                    <input type="text" id="c-name" name="c-name" class="form-control" value="${this.character.name}" @blur=${this.saveName} />
                    <button type="button" class="btn btn-secondary btn-sm" @click=${this.#createName}>Generate Name</button>
                </div>
            </div>

            <div class="row mb-3 align-items-center">
                <div class="col-sm-3"><strong>Profession</strong></div>
                <div class="col-auto">
            ${this.character.profession === 0
        ? html`<a id="c-profession" href="#" @click=${this.showProfessions}>Choose a profession</a>`
        : html`<div>${profession?.name || ''} ($${profession?.cost || 0})</div>`
}
                </div>
            </div>

            ${this._attributeList()}

            <div class="row mb-3 align-items-center">
                <label for="c-handed" class="col-sm-3 col-form-label">Main Hand</label>
                <div class="col-auto">
                ${allowFullEdit
        ? html`<div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="c-handed" id="handedr" value="R" autocomplete="off" ?checked=${this.character.handed === 'R'} @change=${this.saveHanded} />
                        <label class="form-check-label" for="handedr">Right</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="c-handed" id="handedl" value="L" autocomplete="off" ?checked=${this.character.handed === 'L'} @change=${this.saveHanded} />
                        <label class="form-check-label" for="handedl">Left</label>
                    </div>`
        : html`${this.character.handed === 'L' ? 'Left' : 'Right'}`}
                </div>
            </div>

            <div class="d-flex justify-content-between mb-4">
                <h3>Weapons</h3>
                <button type="button" class="btn btn-secondary btn-sm" @click=${this.showWeapons}>Add Weapon</button>
            </div>
            <ul class="weapons">
                ${this.character.weapons.map((id) => this.#weaponList(id))}
            </ul>

            <div class="d-flex justify-content-between mb-4">
                <h3>Upgrades</h3>
                ${allowFullEdit && !isFarmhand ? html`<button type="button" class="btn btn-secondary btn-sm" @click=${this.showUpgrades}>Add Upgrade</button>` : ''}
            </div>
            <ul class="upgrades">
                ${this.character.upgrades.map((id) => this.#upgradeList(id))}
            </ul>

            <div class="d-flex justify-content-between mb-4">
                <h3>Flaws</h3>
                ${allowFullEdit && !isFarmhand ? html`<button type="button" class="btn btn-secondary btn-sm" @click=${this.showFlaws}>Add Flaw</button>` : ''}
            </div>
            <ul class="flaws">
                ${this.character.flaws.map((id) => this.#flawList(id))}
            </ul>

            <div class="d-flex justify-content-between mb-4">
                <h3>Equipment</h3>
                <button type="button" class="btn btn-secondary btn-sm" @click=${this.showEquipment}>Add Equipment</button>
            </div>
            <ul class="equipment">
                ${this.character.equipment.map((id) => this.#equipmentList(id))}
            </ul>
            ${!this.gang.draft
        ? html`<div class="d-flex justify-content-between mb-4">
                <h3>Knicknacks</h3>
                <button type="button" class="btn btn-secondary btn-sm" @click=${this.showKnicknacks}>Add Knicknack</button>
            </div>
            <ul class="knicknack">
                ${this.character.knicknacks.map((id) => this.#knicknackList(id))}
            </ul>`
        : ''}

            ${this.#notorietyTraits()}
        `;
    }
}

if (!window.customElements.get('dbl-character-edit')) {
    window.customElements.define('dbl-character-edit', CharacterEdit);
}
