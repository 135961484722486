export default [
    {
        id: 1,
        name: 'Iron 1',
        cost: 12,
        attribute: 'iron',
        modifier: 1
    },
    {
        id: 2,
        name: 'Iron 2',
        cost: 25,
        attribute: 'iron',
        modifier: 2
    },
    {
        id: 3,
        name: 'Iron 3',
        cost: 39,
        attribute: 'iron',
        modifier: 3
    },
    {
        id: 4,
        name: 'Grit 1',
        cost: 11,
        attribute: 'grit',
        modifier: 1
    },
    {
        id: 5,
        name: 'Grit 2',
        cost: 23,
        attribute: 'grit',
        modifier: 2
    },
    {
        id: 6,
        name: 'Grit 3',
        cost: 36,
        attribute: 'grit',
        modifier: 3
    },
    {
        id: 7,
        name: 'Brawl 1',
        cost: 10,
        attribute: 'brawl',
        modifier: 1
    },
    {
        id: 8,
        name: 'Brawl 2',
        cost: 21,
        attribute: 'brawl',
        modifier: 2
    },
    {
        id: 9,
        name: 'Brawl 3',
        cost: 33,
        attribute: 'brawl',
        modifier: 3
    },
    {
        id: 10,
        name: 'Luck 1',
        cost: 12,
        attribute: 'luck',
        modifier: 1
    },
    {
        id: 11,
        name: 'Luck 2',
        cost: 25,
        attribute: 'luck',
        modifier: 2
    },
    {
        id: 12,
        name: 'Luck 3',
        cost: 39,
        attribute: 'luck',
        modifier: 3
    },
    {
        id: 13,
        name: 'Expert Rider',
        cost: 12,
        description: '+2" movement while mounted per AP'
    },
    {
        id: 14,
        name: 'Smooth Talker',
        cost: 20,
        description: 'Save $2 on all weapons/equipment for whole gang (minimum $1 cost). Does not stack for multiple characters.',
        print: false
    },
    {
        id: 15,
        name: 'Professional Gambler',
        cost: 18,
        description: 'May force reroll of a single roll once per game.'
    },
    {
        id: 16,
        name: 'Well-Traveled',
        cost: 14,
        description: '+2" move per AP on first activation of game.'
    }
];
