export default class SpecialAbility {
    id = 0;
    name = '';
    description = '';

    constructor ({
        id = 0,
        name = '',
        description = ''
    }) {
        this.id = id;
        this.name = name;
        this.description = description;
    }
}
