import knicknacks from '../data/knicknacks.js';
import Knicknack from '../models/Knicknack.js';

const models = knicknacks.map((e) => {
    return new Knicknack(e);
});

const getKnicknack = function (id) {
    return models.find((obj) => {
        return obj.id === id;
    });
};

const getKnicknacks = function () {
    return models.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
};

const getKnicknackCost = function (id) {
    return getKnicknack(id)?.cost || 0;
};

export {
    getKnicknack,
    getKnicknacks,
    getKnicknackCost
};
