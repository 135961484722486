import equipment from '../data/equipment.js';
import Equipment from '../models/Equipment.js';

const models = equipment.map((e) => {
    return new Equipment(e);
});

const getEquipment = function (id) {
    return models.find((obj) => {
        return obj.id === id;
    });
};

const getAllEquipment = function () {
    return models.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
};

const getEquipmentCost = function (id) {
    return getEquipment(id)?.cost || 0;
};

export {
    getEquipment,
    getAllEquipment,
    getEquipmentCost
};
