import { html, css } from 'lit';
import BaseElement from './BaseElement.js';
import { emitter, getGang } from '../services/GangService.js';
import Gang from '../models/Gang';
import GangEdit from './GangEdit.js';
import RivalEdit from './RivalEdit.js';
import GangExport from './GangExport.js';
import GangImport from './GangImport.js';
import { createGang } from '../services/RivalService.js';

export default class GangPage extends BaseElement {
    static styles = [
        super.styles,
        css`
        :host {
            overflow: hidden;
            flex: 1;
            height: 100%;
            max-height: 100%;
        }
        :host > div {
            display: flex;
            flex-direction: column;
            height: 100%;
            max-height: 100%;
        }
        :host > div > div {
            flex: 0;
            padding: 0;
            width: 100%;
            max-width: 100%;
        }
        :host main {
            display: flex;
            overflow: hidden;
            flex: 1;
        }
        :host main > div {
            flex: 1;
            padding: 1rem 2rem;
            overflow: auto;
            border-right: 1px solid gray;
            margin-bottom: 1rem;
        }
        :host main > div.active {

        }
        :host main > div:last-child {
            border-right: none;
        }

        ul {
            padding: 0 .5rem 0 .5rem;
            margin: 0;
            display: grid;
            gap: .5rem;
            grid-template-columns: repeat(3, 1fr);
            list-style-type: none;
            border-width: 0 0 1px 0;
            border-color: black;
            border-style: solid;
        }
        li {
            margin: 0;
            padding: 0;
            text-align: center;
            border: 1px solid grey;
            border-width: 1px 1px 0px 1px;
            border-radius: .5rem .5rem 0 0;
            background-color: #eee;

        }
        li.active {
            background-color: white;
            border-bottom: 0;
            bottom: 1px;
            transform: translateY(1px);
        }
        li a {
            text-decoration: none;
            padding: .5rem 1rem;
            display: inline-block;
            width: 100%;
            color: inherit;
        }
        li.active a {
            color: black;
        }

        /* Empty columns cause tabs to be invisible */
        div:has(#col-2:empty) li:has(a[data-col="2"]) {
            background-color: transparent;
            border-color: transparent;
            color: transparent;
            pointer-events: none;
        }
        div:has(#col-3:empty) li:has(a[data-col="3"]) {
            background-color: transparent;
            border-color: transparent;
            color: transparent;
            pointer-events: none;
        }

        @media(width <= 1000px) {

            :host main {
                display: block;
                overflow: auto;
            }
            :host main > div {
                border: none;
                width: 100%;
                background-color: white;
                display: none;
            }
            :host main > div.active {
                display: block;
            }
        }
        `
    ];

    constructor () {
        super();
        this.setAttribute('role', 'main');

        this.showCreateHandler = this.#showCreate.bind(this);
        this.showImportHandler = this.#showImport.bind(this);
        this.showExportHandler = this.#showExport.bind(this);
        this.gangSaveHandler = this.#handleGangSave.bind(this);
        this.createRivalHandler = this.#createRival.bind(this);
    }

    connectedCallback () {
        super.connectedCallback();

        emitter.on('gang:show:edit', this.showCreateHandler);
        emitter.on('gang:show:import', this.showImportHandler);
        emitter.on('gang:show:export', this.showExportHandler);
        emitter.on('gang:edit', this.gangSaveHandler);
        emitter.on('gang:remove', () => {
            this.clearColumns();
        });
        emitter.on('character:remove', ({ id }) => {
            this.#removeCharacter(id);
        });
        emitter.on('animal:remove', ({ id }) => {
            this.#removeAnimal(id);
        });
        emitter.on('rival:show:edit', this.createRivalHandler);
    }

    disconnectedCallback () {
        super.disconnectedCallback();

        emitter.off('gang:show:edit', this.showCreateHandler);
        emitter.off('gang:show:import', this.showImportHandler);
        emitter.off('gang:show:export', this.showExportHandler);
        emitter.off('gang:edit', this.gangSaveHandler);
        emitter.off('rival:show:edit', this.createRivalHandler);
    }

    #changeTab (ev) {
        ev.preventDefault();
        const a = ev.currentTarget;
        const col = a.dataset.col || 1;
        this.#switchToTab(col);
    }

    #switchToTab (which) {
        const column = this.renderRoot.querySelector(`#col-${which}`);
        if (!column) {
            return;
        }
        if (column.innerHTML === '') {
            return;
        }
        this.renderRoot.querySelectorAll('li').forEach((el) => el.classList.remove('active'));
        this.renderRoot.querySelector(`li:has(a[data-col="${which}"])`)?.classList.add('active');

        this.renderRoot.querySelectorAll('main > div').forEach((el) => el.classList.remove('active'));
        column.classList.add('active');
    }

    clearColumns (col2 = true, col3 = true) {
        if (col3) {
            this.renderRoot.querySelector('#col-3').innerHTML = '';
        }
        if (col2) {
            this.renderRoot.querySelector('#col-2').innerHTML = '';
        }
        if (col2 && col3) {
            this.#switchToTab(1);
            return;
        }
        this.#switchToTab(2);
    }

    fillColumn (el, which = 2, title = 'Character') {
        this.clearColumns(which === 2, which === 3);
        this.renderRoot.querySelector(`#col-${which}`)?.append(el);
        this.renderRoot.querySelector(`a[data-col="${which}"]`).innerText = title;
        this.#switchToTab(which);
    }

    clearFirstColumn () {
        this.renderRoot.querySelector('#col-1 > div').innerHTML = '';
    }

    fillFirstColumn (el) {
        this.renderRoot.querySelector('#col-1 > div').append(el);
    }

    #showCreate ({ uuid = '' }) {
        this.clearColumns(true, true);
        this.clearFirstColumn();
        let gang = null;
        if (uuid === '') {
            gang = new Gang({ cash: 100 });
        } else {
            gang = getGang(uuid);
            if (!gang) {
                return;
            }
        }
        let form = null;
        if (gang.rival) {
            form = new RivalEdit({ gang });
        } else {
            form = new GangEdit({ gang });
        }
        this.fillFirstColumn(form);
    }

    #createRival () {
        this.clearColumns(true, true);
        this.clearFirstColumn();
        const gang = createGang();
        const form = new RivalEdit({ gang });
        this.fillFirstColumn(form);
    }

    #showImport () {
        this.clearColumns(true, true);
        this.clearFirstColumn();
        const form = new GangImport({});
        this.fillFirstColumn(form);
    }

    #showExport () {
        this.clearColumns(true, true);
        this.clearFirstColumn();
        const form = new GangExport({});
        this.fillFirstColumn(form);
    }

    #handleGangSave () {
        this.clearColumns(true, true);
    }

    #removeCharacter (id) {
        const char = this.renderRoot.querySelector(`#col-2 dbl-character-edit[data-id="${id}"]`);
        if (char) {
            char.remove();
        }
    }

    #removeAnimal (id) {
        const animal = this.renderRoot.querySelector(`#col-2 dbl-animal-edit[data-id="${id}"]`);
        if (animal) {
            animal.remove();
        }
    }

    render () {
        return html`<div>
        <div>
            <ul>
                <li class="active"><a href="#" data-col=1 @click=${this.#changeTab}>Gangs</a></li>
                <li><a href="#" data-col=2 @click=${this.#changeTab}>Character</a></li>
                <li><a href="#" data-col=3 @click=${this.#changeTab}>XXX</a></li>
            </ul>
        </div>
    <main>
        <div id="col-1" class="active">
            <dbl-gang-list></dbl-gang-list>
            <div></div>
        </div>
        <div id="col-2"></div>
        <div id="col-3"></div>
    </main>
    </div>`;
    }
}

if (!window.customElements.get('dbl-gang-page')) {
    window.customElements.define('dbl-gang-page', GangPage);
}
