export default [
    {
        id: 1,
        name: 'Scout',
        cost: 6,
        iron: 1,
        brawl: 2,
        grit: 2,
        luck: 1,
        special: 1
    },
    {
        id: 2,
        name: 'Rustler',
        cost: 6,
        iron: 2,
        brawl: 2,
        grit: 2,
        luck: 2,
        special: 2
    },
    {
        id: 3,
        name: 'Drifter',
        cost: 9,
        iron: 3,
        brawl: 2,
        grit: 2,
        luck: 2,
        special: 3
    },
    {
        id: 4,
        name: 'Enforcer',
        cost: 9,
        iron: 2,
        brawl: 3,
        grit: 3,
        luck: 1,
        special: 4
    },
    {
        id: 5,
        name: 'Pursuer',
        cost: 10,
        iron: 2,
        brawl: 2,
        grit: 4,
        luck: 2,
        special: 5
    },
    {
        id: 6,
        name: 'Leader',
        cost: 11,
        iron: 2,
        brawl: 2,
        grit: 3,
        luck: 4,
        special: 6
    },
    {
        id: 7,
        name: 'Farmhand',
        cost: 0,
        iron: 1,
        brawl: 1,
        grit: 1,
        luck: 1,
        special: 0,
        note: 'Can only be taken to replace a killed character. Cannot be given Upgrades or Flaws.'
    }
];
