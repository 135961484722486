import upgrades from '../data/upgrades.js';
import Upgrade from '../models/Upgrade.js';

const models = upgrades.map((w) => {
    return new Upgrade(w);
});

const getUpgrade = function (id) {
    return models.find((obj) => {
        return obj.id === id;
    });
};

const getUpgrades = function () {
    return models;
};

const getUpgradeIdsByAttribute = function (attribute) {
    return models
        .filter((obj) => {
            return obj.attribute === attribute;
        })
        .map((obj) => {
            return obj.id;
        });
};

const getUpgradeCost = function (id) {
    return getUpgrade(id)?.cost || 0;
};

export {
    getUpgrade,
    getUpgrades,
    getUpgradeIdsByAttribute,
    getUpgradeCost
};
