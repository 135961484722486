export default class Profession {
    id = 0;
    name = '';
    cost = 0;
    special = 0;
    iron = 0;
    brawl = 0;
    grit = 0;
    luck = 0;
    note = '';

    constructor ({
        id = 0,
        name = '',
        cost = 0,
        special = 0,
        iron = 0,
        brawl = 0,
        grit = 0,
        luck = 0,
        note = ''
    }) {
        this.id = Number(id);
        this.name = name;
        this.cost = cost;
        this.special = special;
        this.iron = iron;
        this.brawl = brawl;
        this.grit = grit;
        this.luck = luck;
        this.note = note;
    }
}
