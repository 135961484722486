export default [
    {
        id: 1,
        name: 'Six Shooter',
        damage: 1,
        range: 'M',
        ammo: 6,
        tags: ['fanning'],
        cost: 12
    },
    {
        id: 2,
        name: 'Heavy Pistol',
        damage: 2,
        range: 'M',
        ammo: 6,
        tags: ['heavy'],
        cost: 14
    },
    {
        id: 3,
        name: 'Repeating Rifle',
        damage: 2,
        range: 'L',
        ammo: 15,
        tags: ['two-handed'],
        cost: 20
    },
    {
        id: 4,
        name: 'Shotgun',
        damage: 5,
        range: 'S',
        ammo: 2,
        tags: ['devastating', 'buckshot', 'two-handed'],
        cost: 16
    },
    {
        id: 5,
        name: 'Buffalo Rifle',
        damage: 3,
        range: 'VL',
        ammo: 1,
        tags: ['devastating', 'slow reload', 'two-handed'],
        cost: 25
    },
    {
        id: 6,
        name: 'Musket',
        damage: 2,
        range: 'L',
        ammo: 1,
        tags: ['slow reload', 'two-handed'],
        cost: 9
    },
    {
        id: 7,
        name: 'Derringer',
        damage: 1,
        range: 'S',
        ammo: 1,
        tags: ['slow reload', 'concealed'],
        cost: 10
    },
    {
        id: 8,
        name: 'Hunting Bow',
        damage: 1,
        range: 'L',
        tags: ['two-handed', 'ready shot'],
        cost: 7
    },
    {
        id: 9,
        name: 'Calvary Sabre',
        damage: 3,
        tags: ['melee', 'calvary'],
        cost: 7
    },
    {
        id: 10,
        name: 'Pickaxe',
        damage: 2,
        tags: ['melee', 'deadly'],
        cost: 5
    },
    {
        id: 11,
        name: 'Tomahawk',
        damage: 3,
        tags: ['melee', 'deadly', 'throwing'],
        cost: 10
    },
    {
        id: 12,
        name: 'Bowie Knife',
        damage: 2,
        tags: ['melee', 'throwing'],
        cost: 4
    }
];
