export default [
    {
        id: 1,
        name: 'Dog',
        cost: 4,
        brawl: 2,
        grit: 1,
        luck: 1,
        move: 10
    },
    {
        id: 2,
        name: 'Horse',
        cost: 16,
        brawl: 0,
        grit: 2,
        luck: 1,
        move: 12
    },
    {
        id: 3,
        name: 'Mule',
        cost: 12,
        brawl: 0,
        grit: 2,
        luck: 1,
        move: 9
    }
];
