import Animal from './Animal.js';
import Character from './Character.js';

export default class Gang {
    uuid = '';
    name = '';
    player_name = '';
    /** @prop Array<Character> */
    characters = [];
    /** @prop Array<Animal> */
    animals = [];
    cash = 0;
    draft = true;

    constructor ({
        uuid = '',
        name = '',
        player_name = '',
        characters = [],
        animals = [],
        cash = 0,
        draft = true
    }) {
        if (!uuid) {
            this.uuid = crypto.randomUUID();
        } else {
            this.uuid = uuid;
        }
        this.name = name;
        this.player_name = player_name;
        this.characters = characters.map((obj) => {
            return new Character(obj);
        });
        this.animals = animals.map((obj) => {
            return new Animal(obj);
        });
        this.cash = cash;
        this.draft = draft;
    }

    getNextCharacterId () {
        const ids = this.characters.map((c) => c.id);
        return Math.max(0, ...ids) + 1;
    }

    getNextAnimalId () {
        const ids = this.animals.map((a) => a.id);
        return Math.max(0, ...ids) + 1;
    }

    getCharacter (id) {
        const index = this.characters.findIndex(
            (ch) => {
                return ch.id === id;
            }
        );
        return index > -1 ? this.characters[index] : null;
    }

    removeCharacter (id) {
        const index = this.characters.findIndex(
            (ch) => {
                return ch.id === id;
            }
        );
        if (index > -1) {
            this.characters.splice(index, 1);
        }
    }

    removeAnimal (id) {
        const index = this.animals.findIndex(
            (a) => {
                return a.id === id;
            }
        );
        if (index > -1) {
            this.animals.splice(index, 1);
        }
    }
}
