import specials from '../data/specials.js';
import SpecialAbility from '../models/SpecialAbility.js';

const models = specials.map((s) => {
    return new SpecialAbility(s);
});

const getSpecial = function (id) {
    return models.find((obj) => {
        return obj.id === id;
    });
};

const getSpecials = function () {
    return models;
};

const getSpecialName = function (id) {
    const spec = getSpecial(id);
    return spec?.name || '';
};

export {
    getSpecial,
    getSpecials,
    getSpecialName
};
