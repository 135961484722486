export default {
    professions: [
        {
            id: 1,
            name: 'Thug',
            iron: 1,
            brawl: 2,
            grit: 2,
            luck: 1,
            special: 'Thugs do not care for the well being of the other rival gang members, as such, a Thug does not have to roll to randomly determine the target when shooting at a target in base contact with an ally.'
        },
        {
            id: 2,
            name: 'Muscle',
            iron: 2,
            brawl: 4,
            grit: 4,
            luck: 2,
            special: 'Models who lose a Brawl against Muscles are reduced to 1 AP during their next activation.'
        },
        {
            id: 3,
            name: 'Ace Shot',
            iron: 4,
            brawl: 2,
            grit: 3,
            luck: 3,
            special: 'Models who are wounded in the Torso hit box by an Ace Shot’s ranged attack suffer 1 additional damage.'
        },
        {
            id: 4,
            name: 'RIval Leader',
            iron: 5,
            brawl: 3,
            grit: 5,
            luck: 3,
            special: 'Assuming the movement is unrestricted, Rival Leaders may move an extra 2"- free of cost - at the end of their activation. Additionally, Rival Leaders may always roll twice on post-game injury tables, choosing either result.'
        }
    ],
    tables: {
        handed: {
            tables: {
                default: [
                    { label: 'R', weight: 9 },
                    { label: 'L', weight: 1 }
                ]
            }
        },
        gang_name: {
            key: 'gang_name',
            title: 'Rival Gang Name',
            sequence: [
                'first',
                'second'
            ],
            tables: {
                first: [
                    'Breakneck',
                    'Copper',
                    'Devil\'s',
                    'Grim',
                    'Cutthroat',
                    'Lawless',
                    'Phantom',
                    'Lucky',
                    'Runaway',
                    'Sixgun',
                    'Vengeful',
                    'Wasteland'
                ],
                second: [
                    'Bunch',
                    'Crew',
                    'Drifters',
                    'Gang',
                    'Few',
                    'Lot',
                    'Party',
                    'Posse',
                    'Raiders',
                    'Riders',
                    'Terrors',
                    'Robber'
                ]
            }
        }
    }
};
