export default [
    {
        id: 2,
        name: 'Trail Jerky',
        description: 'Gives a character +1 Brawl in their next melee combat. This effect does not stack with itself.',
        consumable: true,
        cost: 1
    },
    {
        id: 3,
        name: 'Bandana',
        description: 'Players with a number of Bandanas in their cache equal to the number of gunslingers in their gang may add +2 to all Initiative rolls.',
        cost: 2
    },
    {
        id: 4,
        name: 'Shaving Razor ',
        description: 'May be equipped as a weapon, and has the same profile as a Bowie Knife without the "Throwing Weapon" feature. Additionally, a Shaving Razor may be used used to remove the "Poor Hygiene" Character Flaw; remove this item from your Gang Cache if used in this manner.',
        cost: 3
    },
    {
        id: 5,
        name: 'Smoking Pipe',
        description: 'Gunslingers in possession of a Smoking Pipe may spend 1 Matchbook and 1AP to restore 1 Luck. This may not bring a gunslinger above their starting Luck value.',
        cost: 4
    },
    {
        id: 6,
        name: 'Fine Flask',
        description: 'Extra Strong Whiskey that is used with a Fine Flask provides +2 Grit instead of +1.',
        cost: 5
    },
    {
        id: 7,
        name: 'Matchbook',
        description: 'This item must be used with a Smoking Pipe.',
        consumable: true,
        cost: 1
    },
    {
        id: 8,
        name: 'Canteen',
        description: 'Canteen may be used once per game to provide an unmounted character with an additional 2" of movement in a single movement action.',
        cost: 6
    },
    {
        id: 9,
        name: 'Compass',
        description: 'A player in possession of a Compass may choose to redeploy all of their models after seeing how the enemy gang deploys.',
        cost: 10
    },
    {
        id: 10,
        name: 'Pocket Bible',
        description: 'A gunslinger may choose to use a Pocket Bible at the expense of 5 Notoriety to permanently remove one Character Flaw.',
        consumable: true,
        cost: 10
    },
    {
        id: 11,
        name: 'Necklace of Ears',
        description: 'A gunslinger in possession of a Necklace of Ears gains 1 Notoriety - starting with the first - any time they roll the Necklace of Ears result on the Knickknacks chart. Players should keep note of the number of times that a character receives this result.',
        cost: 1
    }
];
