import { html } from 'lit';
import SpeciesDetails from '../components/SpeciesDetails.js';
import { getAllSpecies } from '../services/AnimalService.js';
import BaseElement from './BaseElement.js';
import { emitter } from '../services/GangService.js';

export default class SpeciesList extends BaseElement {
    constructor ({ animalId = 0, speciesId = 0 }) {
        super();
        this.animalId = animalId;
        this.speciesId = speciesId;

        this.handlerUpdate = this.#handleUpdate.bind(this);
    }

    connectedCallback () {
        super.connectedCallback();
        emitter.on('animal:species:update', this.handlerUpdate);
    }

    disconnectedCallback () {
        super.disconnectedCallback();
        emitter.off('animal:species:update', this.handlerUpdate);
    }

    close () {
        this.getRootNode().host?.clearColumns(false, true);
    }

    #handleUpdate ({ speciesId, animalId }) {
        this.renderRoot.querySelectorAll('dbl-species-details').forEach((el) => {
            if (el?.species?.id === speciesId) {
                el.setAttribute('selected', true);
            } else {
                el.removeAttribute('selected');
            }
        });
    }

    render () {
        return html`<div class="d-flex justify-content-between align-items-center mb-3">
        <h3>Choose Species</h3>
        <button type="button" class="btn btn-secondary btn-sm" @click=${this.close}>Close</button>
        </div>
        <ul class="list-group">
            ${getAllSpecies().map((species) => {
        return new SpeciesDetails({ species, selected: species.id === this.speciesId, animalId: this.animalId });
    })}
        `;
    }
}

if (!window.customElements.get('dbl-species-list')) {
    window.customElements.define('dbl-species-list', SpeciesList);
}
