import professions from '../data/professions.js';
import Profession from '../models/Profession.js';
import rivals from '../data/rivals.js';

const { professions: rivalProfessions } = rivals;

const models = professions.map((p) => {
    return new Profession(p);
});

const getProfession = function (id) {
    return models.find((obj) => {
        return obj.id === id;
    });
};

const getProfessions = function () {
    return models;
};

const getProfessionName = function (id) {
    const prof = getProfession(id);
    return prof?.name || '';
};

const getProfessionCost = function (id) {
    return getProfession(id)?.cost || 0;
};

const getRivalProfession = function (id) {
    return rivalProfessions.find((p) => {
        return p.id === id;
    });
};

export {
    getProfession,
    getProfessions,
    getProfessionName,
    getProfessionCost,
    getRivalProfession
};
