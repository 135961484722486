export default [
    {
        id: 1,
        name: 'Keen Eyes',
        description: '+2" optimal range for all weapons'
    },
    {
        id: 2,
        name: 'Open Range',
        description: '+1 DT while out of cover'
    },
    {
        id: 3,
        name: 'Quick Draw',
        description: '+2 shoot when returning fire'
    },
    {
        id: 4,
        name: 'Command Respect',
        description: '+1 to ranged attacks against higher Notoriety enemies'
    },
    {
        id: 5,
        name: 'True Grit',
        description: 'On a 10+ can negate any wound that would go above Grit'
    },
    {
        id: 6,
        name: 'Powerful Presence',
        description: 'Allies in 12" can spend this character\'s Luck'
    }
];
