import { html, css } from 'lit';
import BaseElement from './BaseElement.js';
import { emitter } from '../services/GangService.js';

export default class NotorietyDetails extends BaseElement {
    static styles = [
        super.styles,
        css`
    :host([selected]) li.list-group-item {
        background-color: lightgoldenrodyellow;
        border-width: 3px;
    }
    `
    ];

    static properties = {
        selected: { type: Boolean }
    };

    constructor ({
        trait,
        selected = false,
        charId = 0
    }) {
        super();
        this.trait = trait;
        this.selected = selected;
        this.charId = charId;
    }

    handleSelect (ev) {
        emitter.trigger('character:notoriety:update', { traitId: this.trait.id, charId: this.charId, level: this.trait.cost });
    }

    render () {
        return html`<li class="list-group-item" @click=${this.handleSelect}>
            <h4>${this.trait.name}</h4>
            ${this.trait.attribute ? html`<div><strong>${this.trait.attribute}</strong> +${this.trait.modifier}` : ''}
            ${this.trait.description ? html`<div>${this.trait.description}</div>` : ''}
        </li>`;
    }
}

if (!window.customElements.get('dbl-notoriety-details')) {
    window.customElements.define('dbl-notoriety-details', NotorietyDetails);
}
