import { html, css } from 'lit';
import { getSpecial } from '../services/SpecialService.js';
import BaseElement from './BaseElement.js';
import { emitter } from '../services/GangService.js';

export default class ProfessionDetails extends BaseElement {
    static styles = [
        super.styles,
        css`
    :host([selected]) li.list-group-item {
        background-color: lightgoldenrodyellow;
        border-width: 3px;
    }
    `
    ];

    static properties = {
        selected: { type: Boolean }
    };

    constructor ({
        profession,
        selected = false,
        charId = 0
    }) {
        super();
        this.profession = profession;
        this.selected = selected;
        this.charId = charId;
        this.special = getSpecial(this.profession.special);
    }

    handleSelect (ev) {
        emitter.trigger('character:profession:update', { profId: this.profession.id, charId: this.charId });
    }

    render () {
        return html`<li class="list-group-item" @click=${this.handleSelect}>
            <h4>${this.profession.name}</h4>
            <p>Cost: $${this.profession.cost}</p>
            <dl class="attributes">
                <dt>Iron</dt>
                <dd>${this.profession.iron}</dd>
                <dt>Brawl</dt>
                <dd>${this.profession.brawl}</dd>
                <dt>Grit</dt>
                <dd>${this.profession.grit}</dd>
                <dt>Luck</dt>
                <dd>${this.profession.luck}</dd>
            </dl>
            ${this.special ? html`<p><strong>${this.special.name}</strong> ${this.special.description}</p>` : ''}
            ${this.profession.note ? html`<p>Note: ${this.profession.note}</p>` : ''}
        </li>`;
    }
}

if (!window.customElements.get('dbl-profession-details')) {
    window.customElements.define('dbl-profession-details', ProfessionDetails);
}
