export default class Animal {
    id = 0;
    name = 'Anonymous';
    species = 0;
    equipment = [];

    #isNew = false;

    constructor ({
        id = 0,
        name = 'Anonymous',
        species = 0,
        equipment = []
    }) {
        this.id = id;
        this.name = name;
        this.species = species;
        this.equipment = equipment;
    }

    get isNew () {
        return this.#isNew;
    }
    set isNew (is) {
        this.#isNew = is;
    }
}
