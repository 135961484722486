import { html } from 'lit';
import AnimalEdit from '../components/AnimalEdit.js';
import { getSpeciesName } from '../services/AnimalService.js';
import BaseElement from './BaseElement.js';
import { emitter } from '../services/GangService.js';

export default class AnimalList extends BaseElement {
    /** @prop {Animal} */
    animal = null;

    constructor ({
        animal,
        gang
    }) {
        super();
        this.animal = animal;
        this.gang = gang;

        this.updateHandler = this.#animalUpdated.bind(this);
    }

    connectedCallback () {
        super.connectedCallback();
        emitter.on('animal:update', this.updateHandler);
    }

    disconnectedCallback () {
        super.disconnectedCallback();
        emitter.off('animal:update', this.updateHandler);
    }

    #animalUpdated ({ id }) {
        if (id === this.animal.id) {
            this.requestUpdate();
        }
    }

    delete () {
        this.dispatchEvent(new CustomEvent('animaldelete', { bubbles: true, composed: true, detail: { id: this.animal.id } }));
    }

    openEdit () {
        const page = document.querySelector('dbl-gang-page');
        if (page) {
            page.clearColumns(true, true);
            page.fillColumn(
                new AnimalEdit({ animal: this.animal, gang: this.gang }),
                2,
                'Animal'
            );
        }
    }

    render () {
        return html`<li class="list-group-item d-flex justify-content-between align-items-center">
            <div>${this.animal.name || 'Unnamed'} (${getSpeciesName(this.animal.species) || 'None'})</div>
            <div>
                <button type="button" class="btn btn-secondary btn-sm" @click="${this.openEdit}">Edit</button>
                <button type="button" class="btn btn-danger btn-sm" @click=${this.delete}>Delete</button>
            </dv>
        </li>`;
    }
}

if (!window.customElements.get('dbl-animal-list')) {
    window.customElements.define('dbl-animal-list', AnimalList);
}
