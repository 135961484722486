import { html } from 'lit';
import CharacterEdit from '../components/CharacterEdit.js';
import RivalCharacterEdit from '../components/RivalCharacterEdit.js';
import { getProfessionName, getRivalProfession } from '../services/ProfessionService.js';
import BaseElement from './BaseElement.js';
import { emitter } from '../services/GangService.js';

export default class CharacterList extends BaseElement {
    /** @prop {Character} */
    character = null;
    constructor ({
        character,
        gang
    }) {
        super();
        this.character = character;
        this.gang = gang;

        this.updateHandler = this.#characterUpdated.bind(this);
    }

    connectedCallback () {
        super.connectedCallback();
        emitter.on('character:update', this.updateHandler);
    }

    disconnectedCallback () {
        super.disconnectedCallback();
        emitter.off('character:update', this.updateHandler);
    }

    #characterUpdated ({ id }) {
        if (id === this.character.id) {
            this.requestUpdate();
        }
    }

    delete () {
        this.dispatchEvent(new CustomEvent('characterdelete', { bubbles: true, composed: true, detail: { id: this.character.id } }));
    }

    openEdit () {
        const page = document.querySelector('dbl-gang-page');
        if (page) {
            const el = this.character.rival
                ? new RivalCharacterEdit({ character: this.character, gang: this.gang })
                : new CharacterEdit({ character: this.character, gang: this.gang });
            page.clearColumns(true, true);
            page.fillColumn(
                el,
                2,
                'Character'
            );
        }
    }

    #getProfName () {
        if (this.character.rival) {
            const prof = getRivalProfession(this.character.profession);
            return prof?.name || 'None';
        }
        return getProfessionName(this.character.profession);
    }

    render () {
        return html`<li class="list-group-item d-flex justify-content-between align-items-center">
            <div>${this.character.name || 'Unnamed'} (${this.#getProfName(this.character.profession) || 'None'})</div>
            <div>
                <button type="button" class="btn btn-secondary btn-sm" @click="${this.openEdit}">Edit</button>
                <button type="button" class="btn btn-danger btn-sm" @click=${this.delete}>Delete</button>
            </dv>
        </li>`;
    }
}

if (!window.customElements.get('dbl-character-list')) {
    window.customElements.define('dbl-character-list', CharacterList);
}
