import rivals from '../data/rivals.js';
import Rival from '../models/Rival.js';
import RivalGang from '../models/RivalGang.js';
import TableRoller from 'rpg-table-randomizer/src/TableRoller.js';
import RandomTable from 'rpg-table-randomizer/src/RandomTable.js';
import { getName } from './NameService.js';
import { rollDie } from 'rpg-table-randomizer/src/dice_roller.js';
import { rivalWeapons } from './WeaponService.js';

const { tables } = rivals;

// Instantiate a table roller
const tableRoller = new TableRoller({});
tableRoller.setTableKeyLookup((key) => {
    const obj = tables[key] || null;
    return new RandomTable(obj);
});

const getHanded = function () {
    const handResult = tableRoller.getTableResultSetByKey('handed');
    return handResult.niceString(true);
};

const getGangName = function () {
    const nameResult = tableRoller.getTableResultSetByKey('gang_name');
    return nameResult.niceString(true);
};

const getWeapon = function () {
    const index = rollDie(`1d${rivalWeapons.length}`);
    return rivalWeapons[index - 1];
};

const getRival = function (id, profession) {
    const name = getName();
    const handed = getHanded();
    const rival = new Rival({
        id,
        name,
        profession,
        handed
    });
    rival.addWeapon(getWeapon());
    return rival;
};

const createGang = function () {
    const gang = new RivalGang({
        name: getGangName()
    });
    const memberCount = rollDie('1d6+2');
    const members = [];
    for (let i = 1; i <= memberCount; i++) {
        let profId = 0;
        if (i === 1) {
            // leader
            profId = 4;
        } else {
            profId = rollDie('1d3');
        }
        const member = getRival(i, profId);
        members.push(member);
    }
    gang.characters = members;
    return gang;
};

export {
    createGang
};
