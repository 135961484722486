import { html, css } from 'lit';
import BaseElement from './BaseElement.js';
import { emitter } from '../services/GangService.js';

export default class EquipmentDetails extends BaseElement {
    static styles = [
        super.styles,
        css`
        :host li {
            transition: background-color .25s linear;
        }
        :host li.clicked {
            background-color: goldenrod;
        }
        `
    ];

    constructor ({
        equipment,
        charId = 0,
        animalId = 0
    }) {
        super();
        this.equipment = equipment;
        this.charId = charId;
        this.animalId = animalId;
    }

    handleSelect (ev) {
        const li = ev.target.closest('li');
        if (li.classList.contains('clicked')) {
            return;
        }
        li.classList.add('clicked');
        if (this.animalId > 0) {
            emitter.trigger('animal:equipment:add', { equipmentId: this.equipment.id, animalId: this.animalId });
        } else {
            emitter.trigger('character:equipment:add', { equipmentId: this.equipment.id, charId: this.charId });
        }
        setTimeout(() => {
            li.classList.remove('clicked');
        }, 500);
    }

    render () {
        return html`<li class="list-group-item" @click=${this.handleSelect}>
            <h4>${this.equipment.name}</h4>
            <div>Cost: $${this.equipment.cost}</div>
            ${this.equipment.attribute ? html`<div>${this.equipment.attribute}: +${this.equipment.modifier}</div>` : ''}
            <div>${this.equipment.description}${this.equipment.consumable ? html` <strong>Consumable</strong>` : ''}</div>
        </li>`;
    }
}

if (!window.customElements.get('dbl-equipment-details')) {
    window.customElements.define('dbl-equipment-details', EquipmentDetails);
}
