import { html, css } from 'lit';
import { emitter } from '../services/GangService.js';
import EquipmentList from './EquipmentList.js';
import SpeciesList from './SpeciesList.js';
import { calcAnimalCost, calcNewEquipmentCost } from '../CostCalculator.js';
import BaseElement from './BaseElement.js';
import { getSpecies, getSpeciesCost } from '../services/AnimalService.js';
import { getAnimalStats } from '../StatCalculator.js';
import { getEquipment, getEquipmentCost } from '../services/EquipmentService.js';

export default class AnimalEdit extends BaseElement {
    static styles = [
        super.styles,
        css``
    ];

    /** @prop {Animal} */
    animal = null;

    constructor ({
        animal,
        gang
    }) {
        super();
        this.animal = animal;
        this.gang = gang;

        this.dataset.id = this.animal.id;

        this.speciesHandler = this.#handleSpeciesUpdate.bind(this);
        this.equipmentHandler = this.#handleEquipmentAdd.bind(this);
    }

    connectedCallback () {
        super.connectedCallback();

        emitter.on('animal:species:update', this.speciesHandler);
        emitter.on('animal:equipment:add', this.equipmentHandler);
        emitter.on('gang:edit', () => {
            this.requestUpdate();
        });
    }

    disconnectedCallback () {
        super.disconnectedCallback();
        emitter.off('animal:species:update', this.speciesHandler);
        emitter.off('animal:equipment:add', this.equipmentHandler);
    }

    // For existing characters (buy/sell weapons/equipment)
    updatePendingCost (cost) {
        if (this.gang.draft) {
            return;
        }
        emitter.trigger('cost:update', { cost });
    }

    #handleSpeciesUpdate ({ speciesId, animalId }) {
        if (animalId !== this.animal.id) {
            return;
        }
        this.animal.species = speciesId;
        emitter.trigger('animal:update', { id: this.animal.id });
        if (!this.gang.draft) {
            const cost = getSpeciesCost(speciesId);
            this.updatePendingCost(cost);
        }
        this.requestUpdate();
    }

    #handleEquipmentAdd ({ equipmentId, animalId }) {
        if (animalId !== this.animal.id) {
            return;
        }
        this.animal.equipment.push(equipmentId);
        emitter.trigger('animal:update', { id: this.animal.id });
        if (!this.gang.draft) {
            const cost = calcNewEquipmentCost(equipmentId, this.gang);
            this.updatePendingCost(cost);
        }
        this.requestUpdate();
    }

    saveName (ev) {
        this.animal.name = ev.target.value;
        emitter.trigger('animal:update', { id: this.animal.id });
    }

    close () {
        document.querySelector('dbl-gang-page')?.clearColumns(true, true);
    }

    showSpecies (ev) {
        ev.preventDefault();
        document.querySelector('dbl-gang-page')?.fillColumn(
            new SpeciesList({ animalId: this.animal.id, speciesId: this.animal.species }),
            3,
            'Species'
        );
    }

    showEquipment (ev) {
        ev.preventDefault();
        document.querySelector('dbl-gang-page')?.fillColumn(
            new EquipmentList({ animalId: this.animal.id }),
            3,
            'Equipment'
        );
    }

    #removeEquipment ({ target }) {
        const id = Number(target.dataset.id || 0);
        const i = this.animal.equipment.findIndex((w) => w === id);
        if (i > -1) {
            this.animal.equipment.splice(i, 1);
            emitter.trigger('animal:update', { id: this.animal.id });
            if (!this.gang.draft) {
                const dividend = this.animal.isNew ? 1 : 2;
                const cost = this.animal.isNew ? calcNewEquipmentCost(id, this.gang) : getEquipmentCost(id);
                const sale = Math.round(cost / dividend);
                this.updatePendingCost(-sale);
            }
            this.requestUpdate();
        }
    }

    _attributeList () {
        const attributes = getAnimalStats(this.animal);
        return html`<dl class="attributes">
        <dt>Iron</dt>
        <dd>-</dd>
        <dt>Brawl</dt>
        <dd>${attributes?.brawl || '-'}</dd>
        <dt>Grit</dt>
        <dd>${attributes?.grit || '-'}</dd>
        <dt>Luck</dt>
        <dd>${attributes?.luck || '-'}</dd>
        <dt>Move</dt>
        <dd>${attributes?.move || '-'}</dd>
    </dl>`;
    }

    #equipmentList (equipmentId) {
        const equipment = getEquipment(equipmentId);
        return html`<li>${equipment.name} $${equipment.cost} <button type="button" class="btn btn-secondary btn-sm" data-id="${equipmentId}" @click=${this.#removeEquipment}>X</button></li>`;
    }

    render () {
        const species = getSpecies(this.animal.species) || {};
        return html`<div class="d-flex justify-content-between align-items-center mb-3">
            <h2>Edit Animal</h2>
            <button type="button" class="btn btn-secondary btn-sm" @click=${this.close}>Close</button>
        </div>
        <div class="mb-3">
            <div><strong>Total Cost</strong> $${calcAnimalCost(this.animal, this.gang)}</div>
        </div>

            <div class="row mb-3 align-items-center">
                <label for="c-name" class="col-sm-3 col-form-label">Name</label>
                <div class="col-sm-9">
                    <input type="text" id="c-name" name="c-name" class="form-control" value="${this.animal.name}" @blur=${this.saveName} />
                </div>
            </div>

            <div class="row mb-3 align-items-center">
                <div class="col-sm-3"><strong>Species</strong></div>
                <div class="col-auto">
            ${this.animal.species === 0
        ? html`<a id="c-species" href="#" @click=${this.showSpecies}>Choose a species</a>`
        : html`<div>${species?.name || ''} ($${species?.cost || 0})</div>`
}
                </div>
            </div>

            ${this._attributeList()}

            <div class="d-flex justify-content-between mb-4">
                <h3>Equipment</h3>
                <button type="button" class="btn btn-secondary btn-sm" @click=${this.showEquipment}>Add Equipment</button>
            </div>
            <ul class="equipment">
                ${this.animal.equipment.map((id) => this.#equipmentList(id))}
            </ul>
        `;
    }
}

if (!window.customElements.get('dbl-animal-edit')) {
    window.customElements.define('dbl-animal-edit', AnimalEdit);
}
