export default class Weapon {
    id = 0;
    name = '';
    cost = 0;
    range = '';
    damage = 0;
    ammo = 0;
    tags = [];

    constructor ({
        id = 0,
        name = '',
        cost = 0,
        range = '',
        damage = 0,
        ammo = 0,
        tags = []
    }) {
        this.id = id;
        this.name = name;
        this.cost = cost;
        this.range = range;
        this.damage = damage;
        this.ammo = ammo;
        this.tags = tags;
    }

    get fullRange () {
        switch (this.range) {
            case 'S':
                return '6"';
            case 'M':
                return '12"';
            case 'L':
                return '18"';
            case 'VL':
                return '24"';
            default:
                return '0"';
        }
    }
}
