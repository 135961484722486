export default class Character {
    id = 0;
    name = 'Anonymous';
    profession = 0;
    /** @prop Array<Number> */
    upgrades = [];
    /** @prop Array<Number> */
    flaws = [];
    /** @prop Array<Number> */
    weapons = [];
    /** @prop Array<Number> */
    equipment = [];
    /** @prop Array<Number> */
    knicknacks = [];
    handed = 'R';
    notoriety = 0;
    notorietyTraits = { 5: 0, 10: 0, 15: 0 };

    #isNew = false;

    constructor ({
        id = 0,
        name = 'Anonymous',
        profession = 0,
        upgrades = [],
        flaws = [],
        weapons = [],
        equipment = [],
        knicknacks = [],
        handed = 'R',
        notoriety = 0,
        notorietyTraits = null
    }) {
        this.id = id;
        this.name = name;
        this.profession = profession;
        this.upgrades = upgrades;
        this.flaws = flaws;
        this.weapons = weapons;
        this.equipment = equipment;
        this.knicknacks = knicknacks;
        this.handed = handed;
        this.notoriety = notoriety;
        if (notorietyTraits) {
            this.notorietyTraits = notorietyTraits;
        }
    }

    get isNew () {
        return this.#isNew;
    }
    set isNew (is) {
        this.#isNew = is;
    }
}
