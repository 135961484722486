import { html } from 'lit';
import EquipmentDetails from '../components/EquipmentDetails.js';
import BaseElement from './BaseElement.js';
import { getAllEquipment } from '../services/EquipmentService.js';

export default class EquipmentList extends BaseElement {
    constructor ({ charId = 0, animalId = 0 }) {
        super();
        this.charId = charId;
        this.animalId = animalId;
    }

    close () {
        this.getRootNode().host?.clearColumns(false, true);
    }

    render () {
        return html`<div class="d-flex justify-content-between align-items-center mb-3">
        <h3>Choose Equipment</h3>
        <button type="button" class="btn btn-secondary btn-sm" @click=${this.close}>Close</button>
        </div>
        <ul class="list-group">
            ${getAllEquipment().map((equipment) => {
        if (this.animalId > 0 && !equipment.animal) {
            return '';
        }
        if (this.animalId === 0 && equipment.animal) {
            return '';
        }
        return new EquipmentDetails({ equipment, charId: this.charId, animalId: this.animalId });
    })}
        `;
    }
}

if (!window.customElements.get('dbl-equipment-list')) {
    window.customElements.define('dbl-equipment-list', EquipmentList);
}
