import { getSpeciesCost } from './services/AnimalService.js';
import { getEquipmentCost } from './services/EquipmentService.js';
import { getProfessionCost } from './services/ProfessionService.js';
import { getUpgradeCost } from './services/UpgradeService.js';
import { getWeaponCost } from './services/WeaponService.js';

const thiefFlawId = 11;
const sixShooterId = 1;
const bowieKnifeId = 12;

// self Damn Coward and Poor Hygiene, and Fear of Heights reduces cost of individual
const characterFlawCostReducers = [
    { id: 3, effect: (i) => { return Math.max(i - 3, 1); } },
    { id: 7, effect: (i) => { return Math.max(i - 2, 1); } },
    { id: 10, effect: (i) => { return Math.max(i - 1, 1); } }
];

// Smooth Talker reduces all weapon/equip prices for gang
const equipmentCostReducers = [
    { id: 14, type: 'upgrade', effect: (i) => { return Math.max(i - 2, 1); } },
    { id: 5, type: 'notoriety', effect: (i) => { return Math.max(i - 2, 1); } }
];

const hireCostReducers = [
    { id: 4, type: 'equipment', effect: (i) => { return Math.max(i - 1, 1); } }
];

const getEquipmentCostReducers = function (gang) {
    const reducers = equipmentCostReducers.filter((reducer) => {
        let has = false;
        switch (reducer.type) {
            case 'upgrade':
                gang.characters.forEach((char) => {
                    if (char.upgrades.includes(reducer.id)) {
                        has = true;
                    }
                });
                return has;
            case 'notoriety':
                gang.characters.forEach((char) => {
                    if (Array.from(Object.values(char.notorietyTraits)).includes(reducer.id)) {
                        has = true;
                    }
                });
                return has;
            default:
                return false;
        }
    });
    return reducers.map((r) => {
        return r.effect;
    });
};

const getHireCostReducers = function (gang) {
    let hasReducer = false;
    gang.characters.forEach((char) => {
        if (char.equipment.includes(4)) {
            hasReducer = true;
        }
    });
    if (hasReducer) {
        return [
            hireCostReducers[0].effect
        ];
    }
    return [];
};

/**
 * @param {Character} char
 * @param {Gang} gang
 */
const calcCharacterCost = function (char, gang) {
    if (!char) {
        return 0;
    }
    let total = getProfessionCost(char.profession);

    const hireReducers = getHireCostReducers(gang);
    hireReducers.forEach((func) => {
        total = func(total);
    });

    char.upgrades.forEach((u) => {
        total += getUpgradeCost(u);
    });

    let hasThief = false;
    char.flaws.forEach((f) => {
        if (f === thiefFlawId) {
            hasThief = true;
        }
        const i = characterFlawCostReducers.findIndex((r) => {
            return r.id === f;
        });
        if (i > -1) {
            const func = characterFlawCostReducers[i].effect;
            total = func(total);
        }
    });

    let thiefFree = false;
    const weaponReducers = getEquipmentCostReducers(gang);
    char.weapons.forEach((wid) => {
        if (!thiefFree && hasThief && [sixShooterId, bowieKnifeId].indexOf(wid) > -1) {
            thiefFree = true;
            return;
        }
        let cost = getWeaponCost(wid);
        if (cost > 0) {
            weaponReducers.forEach((func) => {
                cost = func(cost);
            });
        }
        total += cost;
    });
    char.equipment.forEach((eid) => {
        let cost = getEquipmentCost(eid);
        if (cost > 0) {
            weaponReducers.forEach((func) => {
                cost = func(cost);
            });
        }
        total += cost;
    });

    return total;
};

const calcAnimalCost = function (animal, gang) {
    let total = getSpeciesCost(animal.species);

    const equipReducers = getEquipmentCostReducers(gang);
    animal.equipment.forEach((eid) => {
        let cost = getEquipmentCost(eid);
        if (cost > 0) {
            equipReducers.forEach((func) => {
                cost = func(cost);
            });
        }
        total += cost;
    });
    return total;
};

/**
 * @param {Gang} gang
 * @returns {Number}
 */
const calcGangCost = function (gang) {
    let total = 0;
    gang.characters.forEach((c) => {
        total += calcCharacterCost(c, gang);
    });
    gang.animals.forEach((a) => {
        total += calcAnimalCost(a, gang);
    });
    return total;
};

/**
 * For weapons added to existing characters.
 * @param {Number} wid Weapon id
 * @param {Gang} gang
 */
const calcNewWeaponCost = function (wid, gang) {
    const weaponReducers = getEquipmentCostReducers(gang);
    let cost = getWeaponCost(wid);
    if (cost > 0) {
        weaponReducers.forEach((func) => {
            cost = func(cost);
        });
    }
    return cost;
};

/**
 * For equipment added to existing characters.
 * @param {Number} eid Weapon id
 * @param {Gang} gang
 */
const calcNewEquipmentCost = function (eid, gang) {
    const equipReducers = getEquipmentCostReducers(gang);
    let cost = getEquipmentCost(eid);
    if (cost > 0) {
        equipReducers.forEach((func) => {
            cost = func(cost);
        });
    }
    return cost;
};

export {
    calcCharacterCost,
    calcGangCost,
    calcAnimalCost,
    calcNewWeaponCost,
    calcNewEquipmentCost
};
