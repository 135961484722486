import { html, css } from 'lit';
import BaseElement from './BaseElement.js';
import { emitter } from '../services/GangService.js';

export default class UpgradeDetails extends BaseElement {
    static styles = [
        super.styles,
        css`
    :host([selected]) li.list-group-item {
        background-color: lightgoldenrodyellow;
        border-width: 3px;
    }
    :host([disabled]) li.list-group-item {
        color: #ddd;
    }
    `
    ];

    static properties = {
        selected: { type: Boolean, reflect: true },
        disabled: { type: Boolean, reflect: true },
        attribute: { type: String, reflect: true }
    };

    constructor ({
        upgrade,
        charId = 0,
        selected = false,
        disabled = false
    }) {
        super();
        this.upgrade = upgrade;
        this.attribute = this.upgrade.attribute;
        this.charId = charId;
        this.selected = selected;
        this.disabled = disabled;
        this.dataset.id = this.upgrade.id;
    }

    handleSelect (ev) {
        if (this.selected) {
            return;
        }
        this.selected = true;
        emitter.trigger('character:upgrade:add', { upgradeId: this.upgrade.id, charId: this.charId });
        const event = new CustomEvent('upgrade-select', {
            bubbles: true,
            composed: true,
            detail: {
                attribute: this.upgrade.attribute,
                id: this.upgrade.id
            }
        });
        this.dispatchEvent(event);
    }

    render () {
        return html`<li class="list-group-item" @click=${this.handleSelect}>
            <h4>${this.upgrade.name}</h4>
            <p>Cost: $${this.upgrade.cost}</p>
            ${this.upgrade.description ? html`<p>${this.upgrade.description}</p>` : ''}
        </li>`;
    }
}

if (!window.customElements.get('dbl-upgrade-details')) {
    window.customElements.define('dbl-upgrade-details', UpgradeDetails);
}
