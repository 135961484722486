import flaws from '../data/flaws.js';
import Flaw from '../models/Flaw.js';

const models = flaws.map((w) => {
    return new Flaw(w);
});

const getFlaw = function (id) {
    return models.find((obj) => {
        return obj.id === id;
    });
};

const getFlaws = function () {
    return models;
};

const getFlawCost = function (id) {
    return getFlaw(id)?.cost || 0;
};

export {
    getFlaw,
    getFlaws,
    getFlawCost
};
