import { html, css } from 'lit';
import { getAnimalStats } from '../StatCalculator.js';
import BaseElement from './BaseElement.js';
import { getEquipment } from '../services/EquipmentService.js';
import { getSpecies } from '../services/AnimalService.js';

export default class AnimalPrint extends BaseElement {
    static styles = [
        css`
        :host {
            display: block;
            border: 1px solid black;
            padding: 1rem;
            margin-bottom: 1rem;
        }
        .flex-between {
            display: flex;
            justify-content: space-between;
            gap: 1rem;
            align-items: center;
        }
        .flex-start {
            display: flex;
            justify-content: flex-start;
            gap: 1rem;
            align-items: center;
        }

    dl {
        margin:0;
        padding: 0;
    }
    dl.attributes {
        display: flex;
        gap: 1rem;
    }
    dl.attributes dt {
        font-weight: bold;
        display: inline-block;
        margin-inline-end: 1rem;
    }
    dl.attributes dd {
        display: inline-block;
        margin: 0;
    }

    h3 {
        font-size: 1rem;
        margin: 0;
        padding: 0;
    }
    ul, ol { margin: 0; padding: 0; }
    li { margin: 0; padding: 0; margin-left: 1rem;}

    .fill-in-box {
        display: inline-block;
        height: 1.5rem;
        width: 1.5rem;
        border: 1px solid black;
        text-align: center;
    }

    .small { font-size: .8rem; }

    .hit-locations {
        display: grid;
        grid-template-columns: 1fr 1fr 5fr;
        gap: .5rem;
        align-items: center;
    }
    .location {
        white-space: nowrap;
    }

    .equipment-list:has(ul:empty) h3 {
        display: none;
    }
    `
    ];

    /** @prop {Animal} */
    animal = null;
    constructor ({
        animal,
        gang
    }) {
        super();
        this.animal = animal;
        this.gang = gang;
    }

    _attributeList () {
        const attributes = getAnimalStats(this.animal);
        const luckBoxes = Array(attributes.luck).fill(1);
        return html`<dl class="attributes flex-between">
        <div>
        <dt>Move</dt>
        <dd class="fill-in-box">${attributes.move}</dd>
        </div>
        <div>
        <dt>Brawl</dt>
        <dd class="fill-in-box">${attributes.brawl}</dd>
        </div>
        <div>
        <dt>Grit</dt>
        <dd class="fill-in-box">${attributes.grit}</dd>
        </div>
        <div>
        <dt>Luck</dt>
        <dd>
            <span class="fill-in-box">${attributes.luck}</span>
            ${luckBoxes.map((i) => {
        return html`<input type="checkbox" class="me-2" />`;
    })}
        </dd>
        </div>
    </dl>`;
    }

    #equipmentList (equipmentId) {
        const equipment = getEquipment(equipmentId);
        if (equipment.description) {
            return html`<li>${equipment.name}: ${equipment.description}</li>`;
        }
        return '';
    }

    render () {
        const species = getSpecies(this.animal.species) || {};
        return html`<div class="flex-between">
                <div><strong>Name</strong> ${this.animal.name} (${species.name})</div>
                <div class="flex-between">
                <h3>Wounds</h3>
                <div class="hit-locations">
                    <div class="location">Torso</div>
                    <div class="fill-in-box"></div>
                    <div class="small">-1 all rolls (Dead on natural 12)</div>
                </div>
                </div>
            </div>

            ${this._attributeList()}

            <div class="equipment-list">
                <h3>Equipment</h3>
                <ul class="equipment">${this.animal.equipment.map((id) => this.#equipmentList(id))}</ul>
            </div>
            </div>
        `;
    }
}

if (!window.customElements.get('dbl-animal-print')) {
    window.customElements.define('dbl-animal-print', AnimalPrint);
}
