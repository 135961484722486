export default class Flaw {
    id = 0;
    name = '';
    attributes = {};
    benefit = '';
    tradeoff = '';
    print_b = true;
    print_t = true;

    constructor ({
        id = 0,
        name = '',
        attributes = {},
        benefit = '',
        tradeoff = '',
        print_b = true,
        print_t = true
    }) {
        this.id = id;
        this.name = name;
        this.attributes = attributes;
        this.benefit = benefit;
        this.tradeoff = tradeoff;
        this.print_b = print_b;
        this.print_t = print_t;
    }
}
