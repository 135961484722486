import { getAllGangsLocal, emitter } from '../services/GangService.js';
import { html, css } from 'lit';
import BaseElement from './BaseElement.js';

export default class GangList extends BaseElement {
    static styles = [
        super.styles,
        css`
        @media (max-width: 600px) {
            .d-flex {
                display: block !important;
            }
            .d-flex > div {
                margin-top: .5rem;
            }
        }
        `
    ];

    gangs = [];

    constructor () {
        super();

        this.updateHandler = this.#gangUpdate.bind(this);
        this.deleteHandler = this.#gangDelete.bind(this);
    }

    connectedCallback () {
        super.connectedCallback();

        this.gangs = getAllGangsLocal();

        emitter.on('gang:edit', this.updateHandler);
        emitter.on('gang:remove', this.deleteHandler);
    }

    disconnectedCallback () {
        super.disconnectedCallback();
        emitter.off('gang:edit', this.updateHandler);
        emitter.off('gang:remove', this.deleteHandler);
    }

    #gangUpdate () {
        this.gangs = getAllGangsLocal();
        this.requestUpdate();
    }

    #gangDelete ({ uuid = '' }) {
        this.renderRoot.querySelector(`li[data-uuid="${uuid}"]`)?.remove();
    }

    showCreate () {
        this.getRootNode().host?.clearColumns(true, true);
        emitter.trigger('gang:show:edit', {});
    }

    #showCreateRival () {
        this.getRootNode().host?.clearColumns(true, true);
        emitter.trigger('rival:show:edit', {});
    }

    showImport () {
        emitter.trigger('gang:show:import');
    }

    showExport () {
        emitter.trigger('gang:show:export');
    }

    showEdit (ev) {
        this.getRootNode().host?.clearColumns(true, true);
        const btn = ev.target;
        const uuid = btn.closest('li')?.dataset?.uuid || '';
        emitter.trigger('gang:show:edit', { uuid });
    }

    #renderGangItem (gang) {
        return html`<li class="list-group-item d-flex justify-content-between align-items-center" data-uuid="${gang.uuid}">
            ${gang.name}
            ${gang.rival ? html` (Rival)` : ''}
            <div>
                <button type="button" class="btn btn-secondary btn-sm me-2" @click="${this.showEdit}">Edit</button>
                <a href="/print.html?gang_id=${gang.uuid}" class="btn btn-secondary btn-sm me-2" target="_blank">Print</a>
                <a href="/play.html?gang_id=${gang.uuid}" class="btn btn-secondary btn-sm me-2" target="_blank">Play</a>
            </div>
        </li>`;
    }

    render () {
        return html`<div class="d-flex justify-content-between align-items-center">
            <h2>Saved Gangs</h2>
            <div>
                <button type="button" class="btn btn-primary btn-sm me-2" @click=${this.showCreate}>Create</button>
                <button type="button" class="btn btn-primary btn-sm me-2" @click=${this.#showCreateRival}>Create Rival</button>
                <button type="button" class="btn btn-primary btn-sm" @click=${this.showImport}>Import</button>
                <button type="button" class="btn btn-primary btn-sm" @click=${this.showExport}>Export</button>
            </div>
        </div>
        <p class="small mt-3">Warning: Gangs are saved to your current browser only. Make backups using the "Export" button to avoid data loss.</p>
        <ul class="list-group mb-4">
            ${this.gangs.map(this.#renderGangItem.bind(this))}
        </ul>`;
    }
}

if (!window.customElements.get('dbl-gang-list')) {
    window.customElements.define('dbl-gang-list', GangList);
}
