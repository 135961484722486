import { html } from 'lit';
import UpgradeDetails from '../components/UpgradeDetails.js';
import BaseElement from './BaseElement.js';
import { getUpgrades, getUpgradeIdsByAttribute } from '../services/UpgradeService.js';
import { emitter } from '../services/GangService.js';

export default class UpgradeList extends BaseElement {
    constructor ({ character = 0 }) {
        super();
        this.character = character;

        this.handleSelectBind = this.#handleSelect.bind(this);
        this.deselectHandler = this.#deselectUpgrade.bind(this);
    }

    connectedCallback () {
        super.connectedCallback();
        this.addEventListener('upgrade-select', this.handleSelectBind);
        emitter.on('upgrade:deselect', this.deselectHandler);
    }

    disconnectedCallback () {
        super.disconnectedCallback();
        this.removeEventListener('upgrade-select', this.handleSelectBind);
        emitter.off('upgrade:deselect', this.deselectHandler);
    }

    close () {
        this.getRootNode().host?.clearColumns(false, true);
    }

    #handleSelect (ev) {
        const { id = 0, attribute = '' } = ev.detail;
        if (!attribute) {
            return;
        }
        this.renderRoot.querySelectorAll(`dbl-upgrade-details[attribute="${attribute}"]`).forEach((el) => {
            const elId = Number(el.dataset.id || 0);
            if (elId !== id) {
                el.disabled = true;
            }
        });
    }

    #deselectUpgrade ({ id }) {
        const up = this.renderRoot.querySelector(`dbl-upgrade-details[data-id="${id}"]`);
        up.selected = false;
        const attribute = up.getAttribute('attribute');
        if (!attribute) {
            return;
        }
        this.renderRoot.querySelectorAll(`dbl-upgrade-details[attribute="${attribute}"]`).forEach((el) => {
            el.disabled = false;
        });
    }

    renderUpgrade (upgrade) {
        let disabled = false;
        const selected = this.character.upgrades.includes(upgrade.id);
        if (upgrade.attribute) {
            const intersection = getUpgradeIdsByAttribute(upgrade.attribute)
                .filter(x => this.character.upgrades.includes(x));
            if (intersection.length > 0 && !intersection.includes(upgrade.id)) {
                disabled = true;
            }
        }
        return new UpgradeDetails({ upgrade, charId: this.character.id, selected, disabled });
    }

    render () {
        return html`<div class="d-flex justify-content-between align-items-center mb-3">
        <h3>Choose Upgrade</h3>
        <button type="button" class="btn btn-secondary btn-sm" @click=${this.close}>Close</button>
        </div>
        <ul class="list-group">
            ${getUpgrades().map((upgrade) => {
        return this.renderUpgrade(upgrade);
    })}
        `;
    }
}

if (!window.customElements.get('dbl-upgrade-list')) {
    window.customElements.define('dbl-upgrade-list', UpgradeList);
}
