import { html, css } from 'lit';
import { getAnimalStats } from '../StatCalculator.js';
import BaseElement from './BaseElement.js';
import { getEquipment } from '../services/EquipmentService.js';
import { getSpeciesName } from '../services/AnimalService.js';

export default class AnimalPlay extends BaseElement {
    static styles = [
        super.styles,
        css`
        :host {
            padding: 0;
        }
        .grid-col-2 {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
            margin-bottom: .5rem;
        }
    dl {
        margin:0;
        padding: 0;
    }
    dl.attributes {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: .5rem;
        align-items: center;
    }
    dl.attributes dt {
        font-weight: bold;
    }
    dl.attributes dd {
        margin: 0;
    }
    #attr-luck-check, dl.attributes hr {
        grid-column: span 2;
    }
    dl.attributes hr {
        margin: 0.25rem 0;
    }

    input[type="checkbox"] {
        font-size: 2rem;
        height: 2rem;
        width: 2rem;
    }

    table.weapons {
        border: 1px solid black;
        border-collapse: collapse;
        width: 100%;
        text-align: left;
        margin-bottom: .5rem;
    }
    table.weapons th, table.weapons td{
        border: 1px solid black;
        padding: 0 .25rem;
    }

    h3 {
        font-size: 1rem;
        margin: 0;
        padding: 0;
        margin-bottom: .5rem;
    }
    ul, ol { margin: 0; padding: 0; }
    li { margin: 0; padding: 0; margin-left: 1rem;}

    .fill-in-box {
        display: inline-block;
        height: 2rem;
        width: 2rem;
        border: 1px solid black;
        text-align: center;
        font-size: 1.5rem;
        line-height: 2rem;
        border-radius: 1rem;
    }

    input[type="number"] {
        width: 100%;
        height: 2rem;
        font-size: 1.5rem;
        border: 1px solid black;
        text-align: center;
        border-radius: 5px;
        max-width: 10rem;
    }

    .small { font-size: .8rem; }

    .hit-locations {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: .25rem;
        align-items: center;
    }
    .location {
        white-space: nowrap;
    }
    .location strong {
        font-size: 1.25rem;
    }
    .hit-locations .small {
        grid-column: span 2;
        padding-left: .25rem;
        text-align: right;
    }
    .hit-locations .small.highlight {
        color: red;
    }
    .hit-locations .wounds {
        text-align: right;
    }

    .equipment-list:has(ul:empty) h3 {
        display: none;
    }

    .boxes-checks {
        gap: 1rem;
        flex-wrap: wrap;
    }
    `
    ];

    /** @prop {Animal} */
    animal = null;
    constructor ({
        animal,
        gang
    }) {
        super();
        this.animal = animal;
        this.gang = gang;
    }

    _attributeList() {
        const speciesName = getSpeciesName(this.animal.species);
        const attributes = getAnimalStats(this.animal);
        const luckBoxes = Array(attributes.luck).fill(1);
        return html`<dl class="attributes">
        <dt>Species</dt>
        <dd>${speciesName}</dd>
        <hr/>
        <dt>Iron</dt>
        <dd class="fill-in-box">${attributes.iron}</dd>
        <dt>Brawl</dt>
        <dd class="fill-in-box">${attributes.brawl}</dd>
        <dt>Grit</dt>
        <dd class="fill-in-box">${attributes.grit}</dd>
        <dt>Luck</dt>
        <dd id="attr-luck" class="fill-in-box">
            ${attributes.luck}</dd>
        <div id="attr-luck-check" class="d-flex align-items-center boxes-checks">
            ${luckBoxes.map(() => {
        return html`<input type="checkbox" class="me-2" />`;
    })}
        </div>
        <dt>Move</dt>
        <dd class="fill-in-box">${attributes.move}</dd>
    </dl>`;
    }
    /**
     * @param {Event} ev
     */
    #applyWound (ev) {
        const wounds = Number(ev.target.value || 0);
        const effectEl = ev.target?.parentElement?.nextElementSibling;
        if (!effectEl || !effectEl.classList.contains('small')) {
            return;
        }
        if (wounds > 0) {
            effectEl.classList.add('highlight');
        } else {
            effectEl.classList.remove('highlight');
        }
    }

    #equipmentList (equipmentId) {
        const equipment = getEquipment(equipmentId);
        if (equipment.description) {
            return html`<li>${equipment.name}: ${equipment.description}</li>`;
        }
        return '';
    }

    render () {
        return html`<div class="grid-col-2">
                <div>
                ${this._attributeList()}
                </div>
                <div>
                    <div class="hit-locations">
                        <div class="location"><strong>1</strong> Head</div>
                        <div class="wounds"><input type="number" data-location="1" min=0 @change=${this.#applyWound} /></div>
                        <div class="small">Dead</div>
                        <div class="location"><strong>2-6</strong> Torso</div>
                        <div class="wounds"><input type="number" data-location="2" min=0 @change=${this.#applyWound} /></div>
                        <div class="small">-1 all rolls (Dead on natural 12)</div>
                    </div>
                </div>
            </div>
            <div class="equipment-list">
                <h3>Equipment</h3>
                <ul class="equipment">${this.animal.equipment.map((id) => this.#equipmentList(id))}</ul>
            </div>
            </div>
        `;
    }
}

if (!window.customElements.get('dbl-animal-play')) {
    window.customElements.define('dbl-animal-play', AnimalPlay);
}
