export default [
    {
        id: 1,
        name: 'Blessed',
        cost: 5,
        attribute: 'luck',
        modifier: 1,
        print: false
    },
    {
        id: 2,
        name: 'Hardened',
        cost: 5,
        attribute: 'grit',
        modifier: 1,
        print: false
    },
    {
        id: 3,
        name: 'Saloon Brawl Experience',
        cost: 5,
        attribute: 'brawl',
        modifier: 1,
        print: false
    },
    {
        id: 4,
        name: 'Sprint',
        cost: 5,
        description: '+1" per AP when using 2AP to move in a turn.'
    },
    {
        id: 5,
        name: 'Haggler',
        cost: 5,
        description: '-$2 to weapon/equipment cost.',
        print: false
    },
    {
        id: 6,
        name: 'Play It Safe',
        cost: 5,
        description: 'After taking ranged damage may move 1"'
    },
    {
        id: 7,
        name: 'Deadly Shot',
        cost: 10,
        attribute: 'iron',
        modifier: 1,
        print: false
    },
    {
        id: 8,
        name: 'Blessed',
        cost: 10,
        attribute: 'luck',
        modifier: 1,
        print: false
    },
    {
        id: 9,
        name: 'Hardened',
        cost: 10,
        attribute: 'grit',
        modifier: 1,
        print: false
    },
    {
        id: 10,
        name: 'Saloon Brawl Experience',
        cost: 10,
        attribute: 'brawl',
        modifier: 1,
        print: false
    },
    {
        id: 11,
        name: 'Ambidextrous',
        cost: 10,
        description: 'No dual wielding penalties. Only -1 for second Heavy Pistol'
    },
    {
        id: 12,
        name: 'Bold Talker',
        cost: 10,
        description: 'May taunt enemies at start of turn. +1 Iron. Enemies get +1 to hit them too.'
    },
    {
        id: 13,
        name: 'Sure-footed',
        cost: 10,
        description: 'No penalty to shoot when moving first.'
    },
    {
        id: 14,
        name: 'Crowd Fighter',
        cost: 10,
        description: 'No bonus for enemies brawling in groups.'
    },
    {
        id: 15,
        name: 'Sniper',
        cost: 10,
        description: 'No outside of range penalty for Rifles or Muskets.'
    },
    {
        id: 16,
        name: 'Deadly Shot',
        cost: 15,
        attribute: 'iron',
        modifier: 1,
        print: false
    },
    {
        id: 17,
        name: 'Blessed',
        cost: 15,
        attribute: 'luck',
        modifier: 1,
        print: false
    },
    {
        id: 18,
        name: 'Hardened',
        cost: 15,
        attribute: 'grit',
        modifier: 1,
        print: false
    },
    {
        id: 19,
        name: 'Saloon Brawl Experience',
        cost: 15,
        attribute: 'brawl',
        modifier: 1,
        print: false
    },
    {
        id: 20,
        name: 'Trick Shooter',
        cost: 15,
        description: `When discarding to determine the
        DT during draws from the Aim Deck, enemy models
        shot by a gunslinger with "Trick Shooter" are not
        allowed to choose the result to discard; they must
        do so randomly. Treat all duplicates as normal
        before determining any discards. As such, players
        are never required to randomly discard duplicates.`
    },
    {
        id: 21,
        name: '"I shall finish the game..."',
        cost: 15,
        description: `Temporary +1 to Iron and +1 to
        Grit while last member of their
        gang in a game. No "Loners".`
    },
    {
        id: 22,
        name: '"Diablero"',
        cost: 15,
        description: `When wounded roll d12, on 11+ it is ignored.`
    },
    {
        id: 23,
        name: 'Deadeye',
        cost: 15,
        description: `Extra +1 when "careful aiming."`
    },
    {
        id: 24,
        name: 'Pistolier',
        cost: 15,
        description: `When shooting with a pistol may use Luck to lower DT.`
    },
    {
        id: 25,
        name: 'Tactician',
        cost: 15,
        description: `Reroll initiative if this character has line of sight to an enemy.`
    }
];
