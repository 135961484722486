import { html } from 'lit';
import ProfessionDetails from '../components/ProfessionDetails.js';
import { getProfessions } from '../services/ProfessionService.js';
import BaseElement from './BaseElement.js';
import { emitter } from '../services/GangService.js';

export default class ProfessionList extends BaseElement {
    constructor ({
        charId = 0,
        profId = 0,
        isDraft = false
    }) {
        super();
        this.charId = charId;
        this.profId = profId;
        this.isDraft = isDraft;

        this.handlerUpdate = this.#handleUpdate.bind(this);
    }

    connectedCallback () {
        super.connectedCallback();

        // @todo think there's a better way to do that
        emitter.on('character:profession:update', this.handlerUpdate);
    }

    disconnectedCallback () {
        super.disconnectedCallback();
        emitter.off('character:profession:update', this.handlerUpdate);
    }

    close () {
        this.getRootNode().host?.clearColumns(false, true);
    }

    #handleUpdate ({ profId, charId }) {
        this.renderRoot.querySelectorAll('dbl-profession-details').forEach((el) => {
            if (el?.profession?.id === profId) {
                el.setAttribute('selected', true);
            } else {
                el.removeAttribute('selected');
            }
        });
    }

    render () {
        return html`<div class="d-flex justify-content-between align-items-center mb-3">
        <h3>Choose Profession</h3>
        <button type="button" class="btn btn-secondary btn-sm" @click=${this.close}>Close</button>
        </div>
        <ul class="list-group">
            ${getProfessions().map((prof) => {
        if (this.isDraft && prof.id === 7) { // farmhands
            return '';
        }
        return new ProfessionDetails({ profession: prof, selected: prof.id === this.profId, charId: this.charId });
    })}
        `;
    }
}

if (!window.customElements.get('dbl-profession-list')) {
    window.customElements.define('dbl-profession-list', ProfessionList);
}
