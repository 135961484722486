import traits from '../data/notoriety.js';
import NotorietyTrait from '../models/NotorietyTrait.js';

const models = traits.map((t) => {
    return new NotorietyTrait(t);
});

const getTrait = function (id) {
    return models.find((obj) => {
        return obj.id === id;
    });
};

const getTraitsByLevel = function (level) {
    return models.filter((t) => {
        return t.cost === level;
    });
};

export {
    getTrait,
    getTraitsByLevel
};
