export default class Rival {
    id = 0;
    name = 'Anonymous';
    profession = 0;
    /** @prop Array<Number> */
    weapons = [12];
    /** @prop Array<Number> */
    equipment = [];
    handed = 'R';

    rival = true;

    constructor ({
        id = 0,
        name = 'Anonymous',
        profession = 0,
        weapons = [12],
        equipment = [],
        handed = 'R'
    }) {
        this.id = id;
        this.name = name;
        this.profession = profession;
        this.weapons = weapons;
        this.equipment = equipment;
        this.handed = handed;
    }

    addWeapon (id) {
        this.weapons.push(id);
    }
}
