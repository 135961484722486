import { html } from 'lit';
import NotorietyDetails from '../components/NotorietyDetails.js';
import { getTraitsByLevel } from '../services/NotorietyService.js';
import BaseElement from './BaseElement.js';
import { emitter } from '../services/GangService.js';

export default class NotorietyList extends BaseElement {
    constructor ({ charId = 0, level = 5, traitId = 0 }) {
        super();
        this.charId = charId;
        this.level = level;
        this.traitId = traitId;

        this.handlerUpdate = this.#handleUpdate.bind(this);
    }

    connectedCallback () {
        super.connectedCallback();

        emitter.on('character:notoriety:update', this.handlerUpdate);
    }

    disconnectedCallback () {
        super.disconnectedCallback();
        emitter.off('character:notoriety:update', this.handlerUpdate);
    }

    close () {
        this.getRootNode().host?.clearColumns(false, true);
    }

    #handleUpdate ({ traitId, charId }) {
        this.renderRoot.querySelectorAll('dbl-notoriety-details').forEach((el) => {
            if (el?.trait?.id === traitId) {
                el.setAttribute('selected', true);
            } else {
                el.removeAttribute('selected');
            }
        });
    }

    render () {
        return html`<div class="d-flex justify-content-between align-items-center mb-3">
        <h3>Choose Notoriety Trait</h3>
        <button type="button" class="btn btn-secondary btn-sm" @click=${this.close}>Close</button>
        </div>
        <ul class="list-group">
            ${getTraitsByLevel(this.level).map((trait) => {
        return new NotorietyDetails({ trait, selected: trait.id === this.traitId, charId: this.charId });
    })}
        `;
    }
}

if (!window.customElements.get('dbl-notoriety-list')) {
    window.customElements.define('dbl-notoriety-list', NotorietyList);
}
