import { removeGangLocal, saveGang, addAnimal, emitter } from '../services/GangService.js';
import { html, css } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import AnimalEdit from './AnimalEdit.js';
import AnimalList from './AnimalList.js';
import CharacterList from './CharacterList.js';
import BaseElement from './BaseElement.js';

export default class RivalEdit extends BaseElement {
    static styles = [
        super.styles,
        css``
    ];

    #unsaved = false;
    #errors = '';

    constructor ({
        gang = null
    }) {
        super();
        this.gang = gang;
        this.characterUpdateHandler = this.#characterUpdated.bind(this);
        this.animalUpdateHandler = this.#animalUpdated.bind(this);
    }

    connectedCallback () {
        super.connectedCallback();

        emitter.on('character:update', this.characterUpdateHandler);
        emitter.on('animal:update', this.animalUpdateHandler);
    }

    disconnectedCallback () {
        super.disconnectedCallback();

        emitter.off('character:update', this.characterUpdateHandler);
        emitter.off('animal:update', this.animalUpdateHandler);
    }

    #characterUpdated ({ id = 0, isNew = false }) {
        this.#unsaved = true;
        this.requestUpdate();
    }

    #animalUpdated ({ id = 0 }) {
        this.#unsaved = true;
        this.requestUpdate();
    }

    save (ev) {
        ev.preventDefault();
        this.#errors = '';
        const formData = new FormData(ev.target);
        this.gang.name = formData.get('g-name').toString();
        try {
            // validateGang(this.gang, newCost);
            saveGang(this.gang);
            this.#unsaved = false;
        } catch (err) {
            this.#errors = err.message;
        }
        this.requestUpdate();
    }

    close () {
        document.querySelector('dbl-gang-page')?.clearColumns();
        this.remove();
    }

    deleteGang () {
        if (!confirm('Are you sure?')) {
            return;
        }
        removeGangLocal(this.gang.uuid);
        this.close();
    }

    deleteCharacter (ev) {
        const id = ev.detail?.id || 0;
        if (id > 0) {
            this.gang.removeCharacter(id);
            this.#unsaved = true;
            this.requestUpdate();
            emitter.trigger('character:remove', { id });
        }
    }

    addAnimal () {
        const animal = addAnimal(this.gang);
        this.#unsaved = true;
        this.requestUpdate();

        const page = document.querySelector('dbl-gang-page');
        if (page) {
            page.clearColumns(true, true);
            page.fillColumn(
                new AnimalEdit({ animal, gang: this.gang }),
                2,
                'Animal'
            );
        }
    }

    deleteAnimal (ev) {
        const id = ev.detail?.id || 0;
        if (id > 0) {
            this.gang.removeAnimal(id);
            this.#unsaved = true;
            this.requestUpdate();
            emitter.trigger('animal:remove', { id });
        }
    }

    #dirty (ev) {
        const input = ev.target;
        const name = input.name.replace('g-', '');
        if (this.gang[name] !== input.value) {
            this.#unsaved = true;
            this.gang[name] = input.value;
            this.requestUpdate();
        }
    }

    render () {
        return html`<div class="d-flex justify-content-between align-items-center mb-3">
            <h2>Edit Gang</h2>
            <button type="button" class="btn btn-secondary btn-sm" @click=${this.close}>Close</button>
        </div>
        <div class="card mt-4">
        <div class="card-body">
            <form @submit="${this.save}" class="mb-4">
                <div class="row mb-3">
                    <label for="g-name" class="col-sm-4 col-form-label">Gang Name</label>
                    <div class="col">
                    <input type="text" id="g-name" name="g-name" class="form-control" value="${this.gang.name}" @blur=${this.#dirty} />
                    </div>
                </div>
                ${this.#errors !== '' ? html`<p class="alert alert-danger">${unsafeHTML(this.#errors)}</p>` : ''}
                ${this.#unsaved ? html`<p class="alert alert-danger">Unsaved Changes</p>` : ''}
                <div class="d-flex justify-content-between align-items-center mb-3">
                    <button type="submit" class="btn btn-primary">Save</button>
                    <div>
                        <button type="button" class="btn btn-danger btn-sm" @click=${this.deleteGang}>Delete</button>
                    </div>
                </div>
            </form>
            <div class="d-flex justify-content-between align-items-center mb-4">
                <h3>Characters</h3>
            </div>
            <ul id="characters" class="list-group mb-3" @characterdelete=${this.deleteCharacter}>
                ${this.gang.characters.map((character) => new CharacterList({ character, gang: this.gang }))}
            </ul>
<!--
            <div class="d-flex justify-content-between align-items-center mb-4">
                <h3>Animals</h3>
                <button type="button" class="btn btn-primary btn-sm" @click=${this.addAnimal}>Add Animal</button>
            </div>
            <ul id="animals" class="list-group" @animaldelete=${this.deleteAnimal}>
                ${this.gang.animals.map((animal) => new AnimalList({ animal, gang: this.gang }))}
            </ul>
-->
        </div></div>`;
    }
}

if (!window.customElements.get('dbl-rival-edit')) {
    window.customElements.define('dbl-rival-edit', RivalEdit);
}
