import { html, css } from 'lit';
import { emitter } from '../services/GangService.js';
import { getRivalProfession } from '../services/ProfessionService.js';
import EquipmentList from './EquipmentList.js';
import WeaponList from './WeaponList.js';
import { getCharacterStats } from '../StatCalculator.js';
import BaseElement from './BaseElement.js';
import { getWeapon } from '../services/WeaponService.js';
import { getEquipment } from '../services/EquipmentService.js';
import Modal from './Modal.js';

export default class RivalCharacterEdit extends BaseElement {
    static styles = [
        super.styles,
        css``
    ];

    /** @prop {Character} */
    character = null;
    constructor ({
        character,
        gang
    }) {
        super();
        this.character = character;
        this.gang = gang;

        this.dataset.id = this.character.id;

        this.weaponHandler = this.#handleWeaponAdd.bind(this);
        this.equipmentHandler = this.#handleEquipmentAdd.bind(this);
    }

    connectedCallback () {
        super.connectedCallback();
        emitter.on('character:weapon:add', this.weaponHandler);
        emitter.on('character:equipment:add', this.equipmentHandler);
        emitter.on('gang:edit', () => {
            this.requestUpdate();
        });
    }

    disconnectedCallback () {
        super.disconnectedCallback();
        emitter.off('character:weapon:add', this.weaponHandler);
        emitter.off('character:equipment:add', this.equipmentHandler);
    }

    #triggerCharacterUpdate () {
        emitter.trigger('character:update', { id: this.character.id, isNew: this.character.isNew });
    }

    #handleWeaponAdd ({ weaponId, charId }) {
        if (charId !== this.character.id) {
            return;
        }
        this.character.weapons.push(weaponId);
        this.#triggerCharacterUpdate();
        this.requestUpdate();
    }

    #handleEquipmentAdd ({ equipmentId, charId }) {
        if (charId !== this.character.id) {
            return;
        }
        this.character.equipment.push(equipmentId);
        this.#triggerCharacterUpdate();
        this.requestUpdate();
    }

    saveName (ev) {
        this.character.name = ev.target.value;
        this.#triggerCharacterUpdate();
    }

    close () {
        document.querySelector('dbl-gang-page')?.clearColumns(true, true);
    }

    showWeapons (ev) {
        ev.preventDefault();
        document.querySelector('dbl-gang-page')?.fillColumn(
            new WeaponList({ charId: this.character.id, rival: true }),
            3,
            'Weapons'
        );
    }

    #removeWeapon ({ target }) {
        const id = Number(target.dataset.id || 0);
        const i = this.character.weapons.findIndex((w) => w === id);
        if (i > -1) {
            this.character.weapons.splice(i, 1);
            this.#triggerCharacterUpdate();
            this.requestUpdate();
        }
    }

    showEquipment (ev) {
        ev.preventDefault();
        document.querySelector('dbl-gang-page')?.fillColumn(
            new EquipmentList({ charId: this.character.id }),
            3,
            'Equipment'
        );
    }

    #removeEquipment ({ target }) {
        const id = Number(target.dataset.id || 0);
        const i = this.character.equipment.findIndex((w) => w === id);
        if (i > -1) {
            this.character.equipment.splice(i, 1);
            this.#triggerCharacterUpdate();
            this.requestUpdate();
        }
    }

    #attrHelp (ev) {
        const helpModal = new Modal();
        helpModal.hidden = true;
        helpModal.header = 'Attributes';
        helpModal.content = `<div>
        <p><strong>Iron</strong> is added to ranged attack rolls.</p>
        <p><strong>Brawl</strong> is added to melee attack rolls.</p>
        <p><strong>Grit</strong> is how many wounds you can take.</p>
        <p><strong>Luck</strong> is a consumable resource for saving against wounds.</p>
        </div>
    `;

        document.body.append(helpModal);
        helpModal.open();
    }

    _attributeList () {
        const attributes = getCharacterStats(this.character);
        let alert = '';
        Object.keys(attributes).forEach((k) => {
            if (attributes[k] > 6) {
                alert = html`<p class="alert alert-danger">Attributes cannot be more than 6.</p>`;
            }
        });
        return html`<div class="d-flex justify-content-between align-items-center mb-3">
        <dl class="attributes">
        <dt>Iron</dt>
        <dd>${attributes.iron}</dd>
        <dt>Brawl</dt>
        <dd>${attributes.brawl}</dd>
        <dt>Grit</dt>
        <dd>${attributes.grit}</dd>
        <dt>Luck</dt>
        <dd>${attributes.luck}</dd>
    </dl>
    <button type="button" class="btn btn-secondary btn-sm" @click=${this.#attrHelp}>?</button>
    </div>
    ${alert}`;
    }

    #weaponList (weaponId) {
        const weapon = getWeapon(weaponId);
        const sellButton = '';
        return html`<li>${weapon.name} $${weapon.cost} <button type="button" class="btn btn-secondary btn-sm" data-id="${weaponId}" @click=${this.#removeWeapon}>X</button> ${sellButton}</li>`;
    }

    #equipmentList (equipmentId) {
        const equipment = getEquipment(equipmentId);
        const sellButton = '';
        return html`<li>${equipment.name} $${equipment.cost} <button type="button" class="btn btn-secondary btn-sm" data-id="${equipmentId}" @click=${this.#removeEquipment}>X</button> ${sellButton}</li>`;
    }

    render () {
        const profession = getRivalProfession(this.character.profession) || {};
        return html`<div class="d-flex justify-content-between align-items-center mb-3">
            <h2>Edit Character</h2>
            <button type="button" class="btn btn-secondary btn-sm" @click=${this.close}>Close</button>
        </div>

            <div class="row mb-3 align-items-center">
                <label for="c-name" class="col-sm-3 col-form-label">Name</label>
                <div class="col-sm-9">
                    <input type="text" id="c-name" name="c-name" class="form-control" value="${this.character.name}" @blur=${this.saveName} />
                </div>
            </div>

            <div class="row mb-3 align-items-center">
                <div class="col-sm-3"><strong>Profession</strong></div>
                <div class="col-auto"><div>${profession?.name}</div>
            </div>

            ${this._attributeList()}

            <div class="row mb-3 align-items-center">
                <label for="c-handed" class="col-sm-3 col-form-label">Main Hand</label>
                <div class="col-auto">${this.character.handed === 'L' ? 'Left' : 'Right'}</div>
            </div>

            <div class="d-flex justify-content-between mb-4">
                <h3>Weapons</h3>
                <button type="button" class="btn btn-secondary btn-sm" @click=${this.showWeapons}>Add Weapon</button>
            </div>
            <ul class="weapons">
                ${this.character.weapons.map((id) => this.#weaponList(id))}
            </ul>

            <div class="d-flex justify-content-between mb-4">
                <h3>Equipment</h3>
                <button type="button" class="btn btn-secondary btn-sm" @click=${this.showEquipment}>Add Equipment</button>
            </div>
            <ul class="equipment">
                ${this.character.equipment.map((id) => this.#equipmentList(id))}
            </ul>
        `;
    }
}

if (!window.customElements.get('dbl-rivalcharacter-edit')) {
    window.customElements.define('dbl-rivalcharacter-edit', RivalCharacterEdit);
}
