export default [
    {
        id: 1,
        name: 'Arrogant',
        attributes: {
            luck: 1
        },
        tradeoff: 'Receives no cover benefit when unwounded.'
    },
    {
        id: 2,
        name: 'Big and Stupid',
        attributes: {
            brawl: 1,
            grit: 1
        },
        tradeoff: '-2" movement per AP'
    },
    {
        id: 3,
        name: 'Damn Coward',
        benefit: '-$3 cost',
        tradeoff: 'When another member of their gang is killed, this character flees to nearest table edge. They may take no actions except moving.',
        print_b: false
    },
    {
        id: 4,
        name: 'Dimwit',
        attributes: {
            grit: 1
        },
        tradeoff: 'Also last character (among all players) to activate.'
    },
    {
        id: 5,
        name: 'Drunkard',
        attributes: {
            grit: 2,
            iron: -1,
            brawl: -1
        },
        tradeoff: 'Will automatically spend $4 from the gang cache on liquor after each game.'
    },
    {
        id: 6,
        name: 'Fast and Scrawny',
        attributes: {
            grit: -1
        },
        benefit: '+1" movement per AP.',
        tradeoff: 'Wounds to this character during Brawls are doubled.'
    },
    {
        id: 7,
        name: 'Fear of Heights',
        benefit: '-$2 cost',
        tradeoff: 'May not willingly climb ladders or terrain. May use stairs.',
        print_b: false
    },
    {
        id: 8,
        name: 'Large Active Bounty',
        attributes: {
            iron: 1,
            brawl: 1,
            luck: -1
        },
        tradeoff: 'Any player who kills this character may collect $1d12 bounty at end of game.'
    },
    {
        id: 9,
        name: 'Loner',
        attributes: {
            iron: 1,
            brawl: 1,
            grit: 1,
            luck: 1
        },
        tradeoff: 'May not have any other gang members, must play as single character.'
    },
    {
        id: 10,
        name: 'Poor Hygiene',
        benefit: '-$1 cost',
        tradeoff: 'Allies may never finish movement within 1" of this character.',
        print_b: false
    },
    {
        id: 11,
        name: 'Thief',
        benefit: 'Starts with free six shooter or bowie knife. The calculator will give you the first of the those in your list for free.',
        tradeoff: 'Between matches, reduces gang cache by $1d12/2.',
        print_b: false
    },
    {
        id: 12,
        name: 'Trigger-Happy',
        attributes: {
            iron: 1
        },
        tradeoff: 'Always first in gang to active.'
    }
];
