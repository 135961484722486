export default [
    {
        id: 1,
        name: 'Bandolier',
        cost: 9,
        description: 'Roll d12 after a shot. On a 12 no ammo is subtracted.'
    },
    {
        id: 2,
        name: 'Dynamite',
        cost: 17,
        description: 'Blow up stuff.',
        consumable: true
    },
    {
        id: 3,
        name: 'Embroidered Leather Saddle',
        cost: 18,
        attribute: 'grit',
        modifier: 2,
        description: '',
        animal: true
    },
    {
        id: 4,
        name: 'Finely Made Hat',
        cost: 10,
        description: 'Reduce hire cost by $1.'
    },
    {
        id: 5,
        name: 'Gold Pocket Watch',
        cost: 25,
        description: 'Automatically win initiative on 11+.'
    },
    {
        id: 6,
        name: 'Lucky Rabbit Foot',
        cost: 6,
        description: '+1 on first Luck roll. 1 max per character.'
    },
    {
        id: 7,
        name: 'Medicine',
        cost: 7,
        description: 'Negate "Illness" or "Snake Bite" on Keepin Busy table.',
        consumable: true
    },
    {
        id: 8,
        name: 'Silver-laced Harmonica',
        cost: 14,
        description: 'Roll extra die on Keepin Busy. Keep or discard both.'
    },
    {
        id: 9,
        name: 'Snake Skin Boots',
        cost: 12,
        attribute: 'move',
        modifier: 1,
        description: 'Add 1" to movement per AP.'
    },
    {
        id: 10,
        name: 'Rope and Lasso',
        cost: 4,
        description: 'Rope people at 6" range.'
    },
    {
        id: 11,
        name: 'Spurs',
        cost: 6,
        description: 'Add 1" move when mounted.'
    },
    {
        id: 12,
        name: 'Warm Poncho',
        cost: 6,
        description: 'Reroll an injury of 3-4 on the Torse Injury roll.',
        consumable: true
    },
    {
        id: 13,
        name: 'Rigged Deck of Cards',
        cost: 15,
        description: 'Once per game: look at and reorder top 4 Aim Deck cards.'
    },
    {
        id: 14,
        name: 'Extra Strong Whiskey',
        cost: 5,
        description: 'Grit +1, Move -1" for the remainder of the game.',
        consumable: true
    },
    {
        id: 15,
        name: 'Rifle Scope',
        cost: 16,
        description: 'Permanently increase the optimal range of a buffalo rifle, repeating rifle, or musket by 1".',
        consumable: true
    },
    {
        id: 16,
        name: 'Locking Picking Kit',
        cost: 3,
        description: 'Negate a 1 on Keepin\' Busy table Four.'
    }
];
