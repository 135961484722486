import { html } from 'lit';
import FlawDetails from '../components/FlawDetails.js';
import BaseElement from './BaseElement.js';
import { getFlaws } from '../services/FlawService.js';
import { emitter } from '../services/GangService.js';

export default class FlawList extends BaseElement {
    constructor ({ character }) {
        super();
        this.character = character;

        this.deselectHandler = this.#deselectFlaw.bind(this);
    }

    connectedCallback () {
        super.connectedCallback();

        emitter.on('flaw:deselect', this.deselectHandler);
    }

    disconnectedCallback () {
        super.disconnectedCallback();

        emitter.off('flaw:deselect', this.deselectHandler);
    }

    close () {
        this.getRootNode().host?.clearColumns(false, true);
    }

    #deselectFlaw ({ id }) {
        this.renderRoot.querySelector(`dbl-flaw-details[data-id="${id}"]`).selected = false;
    }

    render () {
        return html`<div class="d-flex justify-content-between align-items-center mb-3">
        <h3>Choose Flaw</h3>
        <button type="button" class="btn btn-secondary btn-sm" @click=${this.close}>Close</button>
        </div>
        <ul class="list-group">
            ${getFlaws().map((flaw) => {
        return new FlawDetails({ flaw, charId: this.character.id, selected: this.character.flaws.includes(flaw.id) });
    })}
        `;
    }
}

if (!window.customElements.get('dbl-flaw-list')) {
    window.customElements.define('dbl-flaw-list', FlawList);
}
