export default class NotorietyTrait {
    id = 0;
    name = '';
    cost = 0;
    attribute = '';
    modifier = 0;
    description = '';
    print = true;

    constructor ({
        id = 0,
        name = '',
        cost = 0,
        attribute = '',
        modifier = '',
        description = '',
        print = true
    }) {
        this.id = id;
        this.name = name;
        this.cost = cost;
        this.attribute = attribute;
        this.modifier = modifier;
        this.description = description;
        this.print = print;
    }
}
