import { html, css } from 'lit';
import BaseElement from './BaseElement.js';
import { emitter } from '../services/GangService.js';

export default class KnicknackDetails extends BaseElement {
    static styles = [
        super.styles,
        css`
        :host li {
            transition: background-color .25s linear;
        }
        :host li.clicked {
            background-color: goldenrod;
        }
        `
    ];

    constructor ({
        knicknack,
        charId = 0
    }) {
        super();
        this.knicknack = knicknack;
        this.charId = charId;
    }

    handleSelect (ev) {
        const li = ev.target.closest('li');
        if (li.classList.contains('clicked')) {
            return;
        }
        li.classList.add('clicked');
        emitter.trigger('character:knicknack:add', { knicknackId: this.knicknack.id, charId: this.charId });
        setTimeout(() => {
            li.classList.remove('clicked');
        }, 500);
    }

    render () {
        return html`<li class="list-group-item" @click=${this.handleSelect}>
            <h4>${this.knicknack.name}</h4>
            <div>Sell for: $${this.knicknack.cost}</div>
            <div>${this.knicknack.description}${this.knicknack.consumable ? html` <strong>Consumable</strong>` : ''}</div>
        </li>`;
    }
}

if (!window.customElements.get('dbl-knicknack-details')) {
    window.customElements.define('dbl-knicknack-details', KnicknackDetails);
}
