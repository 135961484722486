import { removeGangLocal, saveGang, addCharacter, addAnimal, emitter, validateGang } from '../services/GangService.js';
import { html, css } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import AnimalEdit from './AnimalEdit.js';
import AnimalList from './AnimalList.js';
import CharacterEdit from './CharacterEdit.js';
import CharacterList from './CharacterList.js';
import BaseElement from './BaseElement.js';
import { calcCharacterCost, calcGangCost } from '../CostCalculator.js';

export default class GangEdit extends BaseElement {
    static styles = [
        super.styles,
        css``
    ];

    #unsaved = false;
    // cost for new weapons/equipment to existing characters
    #pendingCost = 0;
    // cost for new hires to existing gang
    #pendingCharacters = {};
    #errors = '';

    constructor ({
        gang = null
    }) {
        super();
        this.gang = gang;
        this.characterUpdateHandler = this.#characterUpdated.bind(this);
        this.animalUpdateHandler = this.#animalUpdated.bind(this);
        this.pendingCostHandler = this.#pendingCostUpdated.bind(this);
    }

    connectedCallback () {
        super.connectedCallback();

        emitter.on('character:update', this.characterUpdateHandler);
        emitter.on('animal:update', this.animalUpdateHandler);
        emitter.on('cost:update', this.pendingCostHandler);
    }

    disconnectedCallback () {
        super.disconnectedCallback();

        emitter.off('character:update', this.characterUpdateHandler);
        emitter.off('animal:update', this.animalUpdateHandler);
        emitter.off('cost:update', this.pendingCostHandler);
    }

    #characterUpdated ({ id = 0, isNew = false }) {
        this.#unsaved = true;
        if (isNew) {
            const newCharCost = calcCharacterCost(this.gang.getCharacter(id), this.gang);
            this.#pendingCharacters[id] = newCharCost;
        }
        this.requestUpdate();
    }

    #animalUpdated ({ id = 0 }) {
        this.#unsaved = true;
        this.requestUpdate();
    }

    #pendingCostUpdated ({ cost = 0 }) {
        if (this.gang.draft) {
            return;
        }
        this.#pendingCost += cost;
        this.requestUpdate();
    }

    save (ev) {
        ev.preventDefault();
        this.#errors = '';
        const formData = new FormData(ev.target);
        this.gang.name = formData.get('g-name').toString();
        this.gang.player_name = formData.get('g-player_name').toString();
        this.gang.cash = Number(formData.get('g-cash') || 0);
        const newDraftStatus = Number(formData.get('g-draft') || 0) > 0;
        let newCost = 0;
        if (this.gang.draft) {
            newCost = calcGangCost(this.gang);
        } else {
            const newCharCost = Object.values(this.#pendingCharacters).reduce((a, b) => a + b, 0);
            newCost = this.#pendingCost + newCharCost;
        }
        try {
            // Confirm new cost won't mean cash goes below zero
            // And a few other things.
            validateGang(this.gang, newCost);
            // If validation passes now we can adjust draft/cash values.
            this.gang.draft = newDraftStatus;
            if (!this.gang.draft) {
                this.gang.cash = this.gang.cash - newCost;
            }
            saveGang(this.gang);
            this.#unsaved = false;
            this.#pendingCost = 0;
            this.#pendingCharacters = { };
            // refresh character edit if open...
        } catch (err) {
            this.#errors = err.message;
        }
        this.requestUpdate();
    }

    close () {
        document.querySelector('dbl-gang-page')?.clearColumns();
        this.remove();
    }

    deleteGang () {
        if (!confirm('Are you sure?')) {
            return;
        }
        removeGangLocal(this.gang.uuid);
        this.close();
    }

    addCharacter () {
        const character = addCharacter(this.gang);
        this.#unsaved = true;
        this.requestUpdate();

        const page = document.querySelector('dbl-gang-page');
        if (page) {
            page.clearColumns(true, true);
            page.fillColumn(
                new CharacterEdit({ character, gang: this.gang }),
                2,
                'Character'
            );
        }
    }

    deleteCharacter (ev) {
        const id = ev.detail?.id || 0;
        if (id > 0) {
            this.gang.removeCharacter(id);
            this.#unsaved = true;
            this.requestUpdate();
            emitter.trigger('character:remove', { id });
        }
    }

    addAnimal () {
        const animal = addAnimal(this.gang);
        this.#unsaved = true;
        this.requestUpdate();

        const page = document.querySelector('dbl-gang-page');
        if (page) {
            page.clearColumns(true, true);
            page.fillColumn(
                new AnimalEdit({ animal, gang: this.gang }),
                2,
                'Animal'
            );
        }
    }

    deleteAnimal (ev) {
        const id = ev.detail?.id || 0;
        if (id > 0) {
            this.gang.removeAnimal(id);
            this.#unsaved = true;
            this.requestUpdate();
            emitter.trigger('animal:remove', { id });
        }
    }

    #dirty (ev) {
        const input = ev.target;
        const name = input.name.replace('g-', '');
        if (this.gang[name] !== input.value) {
            this.#unsaved = true;
            this.gang[name] = name === 'cash' ? Number(input.value) : input.value;
            this.requestUpdate();
        }
    }

    #getDraftCheck () {
        if (!this.gang.draft) {
            return '';
        }
        return html`<div class="row mb-3">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value=1 name="g-draft" id="g-draft" checked=checked>
                <label class="form-check-label" for="g-draft">
                    Gang is new. Until this is unchecked all costs are recalculated with each change. After this is unchecked: existing characters cannot buy upgrades/flaws and any cost reductions only apply to newly purchased weapons/equipment or newly hired characters.
                </label>
            </div>
        </div>`;
    }

    #getCashFields () {
        if (this.gang.draft) {
            const cost = calcGangCost(this.gang);
            return html`
            <div class="row mb-3">
                <label for="g-cash" class="col-auto col-form-label">Cash</label>
                <div class="col-sm-6">
                    <input type="text" id="g-cash" name="g-cash" class="col form-control" .value="${this.gang.cash}" @blur=${this.#dirty} />
                </div>
            </div>
            <div class="row mb-3">
                <label for="g-cost" class="col-auto col-form-label">Cost</label>
                <div class="col-sm-6">
                    <input type="text" id="g-cost" name="g-cost" readonly class="col form-control-plaintext" .value="$${cost}" />
                </div>
            </div>
            <div class="row mb-3">
                <label for="g-cache" class="col-auto col-form-label">Left</label>
                <div class="col-sm-2">
                    <input type="text" id="g-cache" name="g-cache" readonly class="col form-control-plaintext" .value="$${this.gang.cash - cost}" />
                </div>
            </div>
            ${cost > this.gang.cash ? html`<p class="alert alert-danger">Cost is greater than cash.</p>` : ''}
            `;
        }
        const newCharCost = Object.values(this.#pendingCharacters).reduce((a, b) => a + b, 0);
        return html`
            <div class="row mb-3">
                <label for="g-cash" class="col-auto col-form-label">Cash</label>
                <div class="col-sm-6">
                    <input type="text" id="g-cash" name="g-cash" class="col form-control" .value="${this.gang.cash}" @blur=${this.#dirty} />
                </div>
            </div>
            <div class="row mb-3">
                <label for="g-pending" class="col-auto col-form-label">Pending Cost</label>
                <div class="col-sm-2">
                    <input type="text" id="g-pending" name="g-pending" readonly class="col form-control-plaintext" .value="$${this.#pendingCost}" />
                </div>
            </div>
            ${newCharCost > 0
        ? html`<div class="row mb-3">
                <label for="g-pendinghire" class="col-auto col-form-label">Pending New Hires</label>
                <div class="col-sm-6">
                    <input type="text" id="g-pendinghire" name="g-pendinghire" readonly class="col form-control-plaintext" value="$${newCharCost}" />
                </div>
            </div>`
        : ''}`;
    }

    render () {
        return html`<div class="d-flex justify-content-between align-items-center mb-3">
            <h2>Edit Gang</h2>
            <button type="button" class="btn btn-secondary btn-sm" @click=${this.close}>Close</button>
        </div>
        <div class="card mt-4">
        <div class="card-body">
            <form @submit="${this.save}" class="mb-4">
                <div class="row mb-3">
                    <label for="g-name" class="col-sm-4 col-form-label">Gang Name</label>
                    <div class="col">
                    <input type="text" id="g-name" name="g-name" class="form-control" value="${this.gang.name}" @blur=${this.#dirty} />
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="g-player_name" class="col-sm-4 col-form-label">Player Name</label>
                    <div class="col">
                    <input type="text" id="g-player_name" name="g-player_name" class="form-control" value="${this.gang.player_name}" @blur=${this.#dirty} />
                    </div>
                </div>
                ${this.#getCashFields()}
                ${this.#getDraftCheck()}
                ${this.#errors !== '' ? html`<p class="alert alert-danger">${unsafeHTML(this.#errors)}</p>` : ''}
                ${this.#unsaved ? html`<p class="alert alert-danger">Unsaved Changes</p>` : ''}
                <div class="d-flex justify-content-between align-items-center mb-3">
                    <button type="submit" class="btn btn-primary">Save</button>
                    <div>
                        <button type="button" class="btn btn-danger btn-sm" @click=${this.deleteGang}>Delete</button>
                    </div>
                </div>
            </form>
            <div class="d-flex justify-content-between align-items-center mb-4">
                <h3>Characters</h3>
                <button type="button" class="btn btn-primary btn-sm" @click=${this.addCharacter}>Add Character</button>
            </div>
            <ul id="characters" class="list-group mb-3" @characterdelete=${this.deleteCharacter}>
                ${this.gang.characters.map((character) => new CharacterList({ character, gang: this.gang }))}
            </ul>

            <div class="d-flex justify-content-between align-items-center mb-4">
                <h3>Animals</h3>
                <button type="button" class="btn btn-primary btn-sm" @click=${this.addAnimal}>Add Animal</button>
            </div>
            <ul id="animals" class="list-group" @animaldelete=${this.deleteAnimal}>
                ${this.gang.animals.map((animal) => new AnimalList({ animal, gang: this.gang }))}
            </ul>
        </div></div>`;
    }
}

if (!window.customElements.get('dbl-gang-edit')) {
    window.customElements.define('dbl-gang-edit', GangEdit);
}
