// calculate the 4 stats

import { getProfession, getRivalProfession } from './services/ProfessionService.js';
import { getUpgrade } from './services/UpgradeService.js';
import { getFlaw } from './services/FlawService.js';
import { getSpecies } from './services/AnimalService.js';
import { getEquipment } from './services/EquipmentService.js';
import { getTrait } from './services/NotorietyService.js';

const getRivalStats = function (char) {
    const prof = getRivalProfession(char.profession);
    return {
        iron: prof?.iron || 0,
        brawl: prof?.brawl || 0,
        grit: prof?.grit || 0,
        luck: prof?.luck || 0
    };
};

const getCharacterStats = function (char) {
    if (char.rival) {
        return getRivalStats(char);
    }
    const prof = getProfession(char.profession);
    const attributes = {
        iron: prof?.iron || 0,
        brawl: prof?.brawl || 0,
        grit: prof?.grit || 0,
        luck: prof?.luck || 0
    };

    char.upgrades.forEach((u) => {
        const upgrade = getUpgrade(u);
        if (!upgrade.attribute) {
            return;
        }
        attributes[upgrade.attribute] += upgrade.modifier;
    });

    char.flaws.forEach((f) => {
        const flaw = getFlaw(f);
        if (!flaw.attributes) {
            return;
        }
        Object.getOwnPropertyNames(flaw.attributes).forEach((attr) => {
            attributes[attr] += flaw.attributes[attr];
        });
    });

    Object.keys(char.notorietyTraits).forEach((level) => {
        const trait = getTrait(char.notorietyTraits[level] || 0);
        if (!trait?.attribute) {
            return;
        }
        attributes[trait.attribute] += trait.modifier;
    });

    return attributes;
};

const getAnimalStats = function (animal) {
    const species = getSpecies(animal.species);
    const attributes = {
        iron: '-',
        brawl: species?.brawl || 0,
        grit: species?.grit || 0,
        luck: species?.luck || 0,
        move: species?.move || '6'
    };

    animal.equipment.forEach((u) => {
        const upgrade = getEquipment(u);
        if (!upgrade.attribute) {
            return;
        }
        attributes[upgrade.attribute] += upgrade.modifier;
    });

    return attributes;
};

export {
    getCharacterStats,
    getAnimalStats
};
