import { html, css } from 'lit';
import { getProfession } from '../services/ProfessionService.js';
import { getCharacterStats } from '../StatCalculator.js';
import BaseElement from './BaseElement.js';
import { getWeapon } from '../services/WeaponService.js';
import { getUpgrade } from '../services/UpgradeService.js';
import { getFlaw } from '../services/FlawService.js';
import { getSpecial } from '../services/SpecialService.js';
import { getEquipment } from '../services/EquipmentService.js';
import { getTrait } from '../services/NotorietyService.js';

export default class CharacterPrint extends BaseElement {
    static styles = [
        css`
        :host {
            border: 2px solid black;
            padding: 1rem;
        }
        .grid-col-2 {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
            margin-bottom: .5rem;
        }
    dl {
        margin:0;
        padding: 0;
    }
    dl.attributes {
        display: grid;
        grid-template-columns: 1fr 1fr 4fr;
        gap: .5rem;
        align-items: top;
    }
    dl.attributes dt {
        font-weight: bold;
    }
    dl.attributes dd {
        margin: 0;
        grid-column: 2 / span 2
    }
    #attr-luck, #attr-handed {
        grid-column: 2;
    }
    #attr-luck-check {
        grid-column: 3;
        grid-row: span 2;

    }


    input[type="checkbox"] {
        margin-inline: 1px;
    }

    table.weapons {
        border: 1px solid black;
        border-collapse: collapse;
        width: 100%;
        text-align: left;
        margin-bottom: .5rem;
    }
    table.weapons th, table.weapons td{
        border: 1px solid black;
        padding: 0 .25rem;
    }

    h3 {
        font-size: 1rem;
        margin: 0;
        padding: 0;
        margin-bottom: .5rem;
    }
    ul, ol { margin: 0; padding: 0; }
    li { margin: 0; padding: 0; margin-left: 1rem;}

    .fill-in-box {
        display: inline-block;
        height: 1.5rem;
        width: 1.5rem;
        border: 1px solid black;
        text-align: center;
    }

    .small { font-size: .8rem; }

    .hit-locations {
        display: grid;
        grid-template-columns: 1fr 1fr 5fr;
        gap: .5rem;
        align-items: center;
    }
    .location {
        white-space: nowrap;
    }

    .equipment-list:has(ul:empty) h3 {
        display: none;
    }
    `
    ];

    /** @prop {Character} */
    character = null;
    constructor ({
        character,
        gang
    }) {
        super();
        this.character = character;
        this.gang = gang;
    }

    _attributeList () {
        const attributes = getCharacterStats(this.character);
        const profession = getProfession(this.character.profession) || {};
        const luckBoxes = Array(attributes.luck).fill(1);
        return html`<dl class="attributes">
        <dt>Name</dt>
        <dd>${this.character.name}</dd>
        <dt>Profession</dt>
        <dd>${profession?.name || ''}</dd>
        <dt>Notoriety</dt>
        <dd class="fill-in-box">${this.character.notoriety > 0 ? this.character.notoriety : ''}</dt>
        <dt>Iron</dt>
        <dd class="fill-in-box">${attributes.iron}</dd>
        <dt>Brawl</dt>
        <dd class="fill-in-box">${attributes.brawl}</dd>
        <dt>Grit</dt>
        <dd class="fill-in-box">${attributes.grit}</dd>
        <dt>Luck</dt>
        <dd id="attr-luck" class="fill-in-box">
            ${attributes.luck}</dd>
        <div id="attr-luck-check">
            ${luckBoxes.map((i) => {
        return html`<input type="checkbox" class="me-2" />`;
    })}
        </div>
        <dt>Handed</dt>
        <dd  id="attr-handed" class="fill-in-box">${this.character.handed}</dd>
    </dl>`;
    }

    #weaponList (weaponId) {
        const weapon = getWeapon(weaponId);
        return html`<tr>
        <td>${weapon.name}</td>
        <td>${weapon.fullRange}</td>
        <td>${weapon.damage}</td>
        <td>${weapon.ammo}</td>
        <td>${weapon.tags.join('; ')}</td>
        </tr>`;
    }

    #upgradeList (upgradeId) {
        const upgrade = getUpgrade(upgradeId);
        if (upgrade.description && upgrade.print) {
            return html`<li>${upgrade.description}</li>`;
        }
        return '';
    }

    #flawList (flawId) {
        const flaw = getFlaw(flawId);
        if (flaw.benefit || flaw.tradeoff) {
            return html`${flaw.benefit && flaw.print_b ? html`<li>${flaw.benefit}</li>` : ''}
            ${flaw.tradeoff && flaw.print_t ? html`<li>${flaw.tradeoff}</li>` : ''}`;
        }
        return '';
    }

    #equipmentList (equipmentId) {
        const equipment = getEquipment(equipmentId);
        if (equipment.description) {
            return html`<li>${equipment.name}: ${equipment.description}</li>`;
        }
        return '';
    }

    #notorietyTraits () {
        const trait5 = getTrait(this.character.notorietyTraits[5] || 0);
        const trait10 = getTrait(this.character.notorietyTraits[10] || 0);
        const trait15 = getTrait(this.character.notorietyTraits[15] || 0);
        return html`${trait5 && trait5.print ? html`<li>${trait5.description}</li>` : ''}
        ${trait10 && trait10.print ? html`<li>${trait10.description}</li>` : ''}
        ${trait15 && trait15.print ? html`<li>${trait15.description}</li>` : ''}`;
    }

    render () {
        const profession = getProfession(this.character.profession) || {};
        return html`<div class="grid-col-2">
                <div>
                ${this._attributeList()}
                </div>
                <div>
                    <h3 class="mb-2">Hit Locations/Wounds</h3>
                    <div class="hit-locations">
                        <div class="location"><strong>1</strong> Head</div>
                        <div class="fill-in-box"></div>
                        <div class="small">Dead</div>
                        <div class="location"><strong>2</strong> Torso</div>
                        <div class="fill-in-box"></div>
                        <div class="small">-1 all rolls (Dead on natural 12)</div>
                        <div class="location"><strong>3</strong> R Arm</div>
                        <div class="fill-in-box"></div>
                        <div class="small">-1 Iron; -1 Brawl</div>
                        <div class="location"><strong>4</strong> L Arm</div>
                        <div class="fill-in-box"></div>
                        <div class="small">-1 Iron; -1 Brawl</div>
                        <div class="location"><strong>5</strong> R Leg</div>
                        <div class="fill-in-box"></div>
                        <div class="small">-1" Move/Wound</div>
                        <div class="location"><strong>6</strong> L Leg</div>
                        <div class="fill-in-box"></div>
                        <div class="small">-1" Move/Wound</div>
                    </div>
                </div>
            </div>
            <div>
                <table class="weapons">
                    <thead>
                        <tr>
                        <th>Weapon</th><th>Range</th><th>Dam.</th><th>Ammo</th><th>Tags</th>
                        </tr>
                    </thead>
                    <tbody>
                    ${this.character.weapons.map((id) => this.#weaponList(id))}
                    </tbody>
                </table>
            </div>
            <div>
                <h3>Traits</h3>
                <ul class="traits">
                    <li>${getSpecial(profession.special)?.description}</li>
                    ${this.character.upgrades.map((id) => this.#upgradeList(id))}
                    ${this.character.flaws.map((id) => this.#flawList(id))}
                    ${this.#notorietyTraits()}
                </ul>
            </div>
            <div class="equipment-list">
                <h3>Equipment</h3>
                <ul class="equipment">${this.character.equipment.map((id) => this.#equipmentList(id))}</ul>
            </div>
            </div>
        `;
    }
}

if (!window.customElements.get('dbl-character-print')) {
    window.customElements.define('dbl-character-print', CharacterPrint);
}
