import animals from '../data/animals.js';
import Species from '../models/Species.js';

const models = animals.map((w) => {
    return new Species(w);
});

const getSpecies = function (id) {
    return models.find((obj) => {
        return obj.id === id;
    });
};

const getAllSpecies = function () {
    return models;
};

const getSpeciesName = function (id) {
    const model = getSpecies(id);
    return model?.name || '';
};

const getSpeciesCost = function (id) {
    const species = getSpecies(id);
    return species?.cost || 0;
};

export {
    getSpecies,
    getAllSpecies,
    getSpeciesName,
    getSpeciesCost
};
