import { html, css } from 'lit';
import { getProfession } from '../services/ProfessionService.js';
import { getCharacterStats } from '../StatCalculator.js';
import BaseElement from './BaseElement.js';
import { getWeapon } from '../services/WeaponService.js';
import { getUpgrade } from '../services/UpgradeService.js';
import { getFlaw } from '../services/FlawService.js';
import { getSpecial } from '../services/SpecialService.js';
import { getEquipment } from '../services/EquipmentService.js';
import { getTrait } from '../services/NotorietyService.js';

export default class CharacterPlay extends BaseElement {
    static styles = [
        super.styles,
        css`
        :host {
            padding: 0;
        }
        .grid-col-2 {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
            margin-bottom: .5rem;
        }
    dl {
        margin:0;
        padding: 0;
    }
    dl.attributes {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: .5rem;
        align-items: center;
    }
    dl.attributes dt {
        font-weight: bold;
    }
    dl.attributes dd {
        margin: 0;
    }
    #attr-luck-check, dl.attributes hr {
        grid-column: span 2;
    }
    dl.attributes hr {
        margin: 0.25rem 0;
    }

    input[type="checkbox"] {
        font-size: 2rem;
        height: 2rem;
        width: 2rem;
    }

    table.weapons {
        border: 1px solid black;
        border-collapse: collapse;
        width: 100%;
        text-align: left;
        margin-bottom: .5rem;
    }
    table.weapons th, table.weapons td{
        border: 1px solid black;
        padding: 0 .25rem;
    }

    h3 {
        font-size: 1rem;
        margin: 0;
        padding: 0;
        margin-bottom: .5rem;
    }
    ul, ol { margin: 0; padding: 0; }
    li { margin: 0; padding: 0; margin-left: 1rem;}

    .fill-in-box {
        display: inline-block;
        height: 2rem;
        width: 2rem;
        border: 1px solid black;
        text-align: center;
        font-size: 1.5rem;
        line-height: 2rem;
        border-radius: 1rem;
    }

    input[type="number"] {
        width: 100%;
        height: 2rem;
        font-size: 1.5rem;
        border: 1px solid black;
        text-align: center;
        border-radius: 5px;
        max-width: 10rem;
    }

    .small { font-size: .8rem; }

    .hit-locations {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: .25rem;
        align-items: center;
    }
    .location {
        white-space: nowrap;
    }
    .location strong {
        font-size: 1.25rem;
    }
    .hit-locations .small {
        grid-column: span 2;
        padding-left: .25rem;
        text-align: right;
    }
    .hit-locations .small.highlight {
        color: red;
    }
    .hit-locations .wounds {
        text-align: right;
    }

    .equipment-list:has(ul:empty) h3 {
        display: none;
    }

    .boxes-checks {
        gap: 1rem;
        flex-wrap: wrap;
    }
    `
    ];

    /** @prop {Character} */
    character = null;
    constructor ({
        character,
        gang
    }) {
        super();
        this.character = character;
        this.gang = gang;
    }

    #reloadAmmo(ev) {
        const parent = ev.target.closest('div');
        parent.querySelectorAll('input[type="checkbox"').forEach((el) => {
            el.checked = false;
        });
    }

    _attributeList () {
        const attributes = getCharacterStats(this.character);
        const luckBoxes = Array(attributes.luck).fill(1);
        return html`<dl class="attributes">
        <dt>Handed</dt>
        <dd  id="attr-handed" class="fill-in-box">${this.character.handed}</dd>
        <dt>Notoriety</dt>
        <dd class="fill-in-box">${this.character.notoriety > 0 ? this.character.notoriety : ''}</dd>
        <hr/>
        <dt>Iron</dt>
        <dd class="fill-in-box">${attributes.iron}</dd>
        <dt>Brawl</dt>
        <dd class="fill-in-box">${attributes.brawl}</dd>
        <dt>Grit</dt>
        <dd class="fill-in-box">${attributes.grit}</dd>
        <dt>Luck</dt>
        <dd id="attr-luck" class="fill-in-box">
            ${attributes.luck}</dd>
        <div id="attr-luck-check" class="d-flex align-items-center boxes-checks">
            ${luckBoxes.map(() => {
        return html`<input type="checkbox" class="me-2" />`;
    })}
        </div>
        <dt>Kills</dt>
        <dd><input type="number" min=0 /></dd>
    </dl>`;
    }
    /**
     * @param {Event} ev
     */
    #applyWound (ev) {
        const wounds = Number(ev.target.value || 0);
        const effectEl = ev.target?.parentElement?.nextElementSibling;
        if (!effectEl || !effectEl.classList.contains('small')) {
            return;
        }
        if (wounds > 0) {
            effectEl.classList.add('highlight');
        } else {
            effectEl.classList.remove('highlight');
        }
    }

    #weaponList (weaponId) {
        const weapon = getWeapon(weaponId);
        return html`<tr>
        <td>${weapon.name}</td>
        <td>${weapon.fullRange}</td>
        <td>${weapon.damage}</td>
        <td>${weapon.tags.join('; ')}</td>
        </tr>`;
    }

    #weaponAmmo (weaponId) {
        const weapon = getWeapon(weaponId);
        const ammoBoxes = Array(weapon.ammo).fill(1);
        if (ammoBoxes.length === 0) {
            return '';
        }
        return html`<div class="mb-2">
            <div><strong>${weapon.name} Ammo</strong></div>
            <div class="d-flex align-items-center boxes-checks">
            ${ammoBoxes.map(() => {
        return html`<input type="checkbox" class="me-2" />`;
    })}
            <button type="button" class="btn btn-secondary ms-4" @click="${this.#reloadAmmo}">Reload</button>
            </div>
        </div>`;
    }

    #upgradeList (upgradeId) {
        const upgrade = getUpgrade(upgradeId);
        if (upgrade.description && upgrade.print) {
            return html`<li>${upgrade.description}</li>`;
        }
        return '';
    }

    #flawList (flawId) {
        const flaw = getFlaw(flawId);
        if (flaw.benefit || flaw.tradeoff) {
            return html`${flaw.benefit && flaw.print_b ? html`<li>${flaw.benefit}</li>` : ''}
            ${flaw.tradeoff && flaw.print_t ? html`<li>${flaw.tradeoff}</li>` : ''}`;
        }
        return '';
    }

    #equipmentList (equipmentId) {
        const equipment = getEquipment(equipmentId);
        if (equipment.description) {
            return html`<li>${equipment.name}: ${equipment.description}</li>`;
        }
        return '';
    }

    #notorietyTraits () {
        const trait5 = getTrait(this.character.notorietyTraits[5] || 0);
        const trait10 = getTrait(this.character.notorietyTraits[10] || 0);
        const trait15 = getTrait(this.character.notorietyTraits[15] || 0);
        return html`${trait5 && trait5.print ? html`<li>${trait5.description}</li>` : ''}
        ${trait10 && trait10.print ? html`<li>${trait10.description}</li>` : ''}
        ${trait15 && trait15.print ? html`<li>${trait15.description}</li>` : ''}`;
    }

    render () {
        const profession = getProfession(this.character.profession) || {};
        return html`<div class="grid-col-2">
                <div>
                ${this._attributeList()}
                </div>
                <div>
                    <div class="hit-locations">
                        <div class="location"><strong>1</strong> Head</div>
                        <div class="wounds"><input type="number" data-location="1" min=0 @change=${this.#applyWound} /></div>
                        <div class="small">Dead</div>
                        <div class="location"><strong>2</strong> Torso</div>
                        <div class="wounds"><input type="number" data-location="2" min=0 @change=${this.#applyWound} /></div>
                        <div class="small">-1 all rolls (Dead on natural 12)</div>
                        <div class="location"><strong>3</strong> R Arm</div>
                        <div class="wounds"><input type="number" data-location="3" min=0 @change=${this.#applyWound} /></div>
                        <div class="small">-1 Iron; -1 Brawl</div>
                        <div class="location"><strong>4</strong> L Arm</div>
                        <div class="wounds"><input type="number" data-location="4" min=0 @change=${this.#applyWound} /></div>
                        <div class="small">-1 Iron; -1 Brawl</div>
                        <div class="location"><strong>5</strong> R Leg</div>
                        <div class="wounds"><input type="number" data-location="5" min=0 @change=${this.#applyWound} /></div>
                        <div class="small">-1" Move/Wound</div>
                        <div class="location"><strong>6</strong> L Leg</div>
                        <div class="wounds"><input type="number" data-location="6" min=0 @change=${this.#applyWound} /></div>
                        <div class="small">-1" Move/Wound</div>
                    </div>
                </div>
            </div>
            <div>
                <table class="weapons">
                    <thead>
                        <tr>
                        <th>Weapon</th><th>Range</th><th>Dam.</th><th>Tags</th>
                        </tr>
                    </thead>
                    <tbody>
                    ${this.character.weapons.map((id) => this.#weaponList(id))}
                    </tbody>
                </table>
            </div>
            <div>
                ${this.character.weapons.map((id) => this.#weaponAmmo(id))}
            </div>
            <div>
                <h3>Traits</h3>
                <ul class="traits">
                    <li>${getSpecial(profession.special)?.description}</li>
                    ${this.character.upgrades.map((id) => this.#upgradeList(id))}
                    ${this.character.flaws.map((id) => this.#flawList(id))}
                    ${this.#notorietyTraits()}
                </ul>
            </div>
            <div class="equipment-list">
                <h3>Equipment</h3>
                <ul class="equipment">${this.character.equipment.map((id) => this.#equipmentList(id))}</ul>
            </div>
            </div>
        `;
    }
}

if (!window.customElements.get('dbl-character-play')) {
    window.customElements.define('dbl-character-play', CharacterPlay);
}
