/* eslint-disable no-unused-vars */
import GangPage from './components/GangPage.js';
import GangList from './components/GangList.js';
import GangPlay from './components/GangPlay.js';
import GangPrint from './components/GangPrint.js';
import Modal from './components/Modal.js';
import { getGang } from './services/GangService.js';

const query = new URLSearchParams(window.location.search);
const gangId = query.get('gang_id');
if (gangId) {
    const gang = getGang(gangId);
    const isPrint = window.location.pathname.match(/print/);
    const isPlay = window.location.pathname.match(/play/);
    if (isPrint) {
        const el = new GangPrint({ gang });
        document.querySelector('main').prepend(el);
    } else if (isPlay) {
        const el = new GangPlay({ gang });
        document.querySelector('.container').prepend(el);
    }
}

document.querySelector('header a.btn-help')?.addEventListener('click', (ev) => {
    ev.preventDefault();

    const helpModal = new Modal();
    document.body.append(helpModal);

    helpModal.hidden = true;
    helpModal.header = 'About / Help';
    const t = document.getElementById('help-general');
    helpModal.content = t.innerHTML;
    helpModal.open();
});
