import Rival from './Rival.js';
import Animal from './Animal.js';

export default class RivalGang {
    uuid = '';
    name = '';
    /** @prop Array<Rival> */
    characters = [];
    animals = [];
    rival = true;

    constructor ({
        uuid = '',
        name = '',
        characters = [],
        animals = []
    }) {
        if (!uuid) {
            this.uuid = crypto.randomUUID();
        } else {
            this.uuid = uuid;
        }
        this.name = name;
        this.characters = characters.map((obj) => {
            return new Rival(obj);
        });
        this.animals = animals.map((obj) => {
            return new Animal(obj);
        });
    }

    getCharacter (id) {
        const index = this.characters.findIndex(
            (ch) => {
                return ch.id === id;
            }
        );
        return index > -1 ? this.characters[index] : null;
    }
}
