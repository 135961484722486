import { html, css } from 'lit';
import { getSpecial } from '../services/SpecialService.js';
import BaseElement from './BaseElement.js';
import { emitter } from '../services/GangService.js';

export default class SpeciesDetails extends BaseElement {
    static styles = [
        super.styles,
        css`
    :host([selected]) li.list-group-item {
        background-color: lightgoldenrodyellow;
        border-width: 3px;
    }
    `
    ];

    static properties = {
        selected: { type: Boolean }
    };

    constructor ({
        species,
        selected = false,
        animalId = 0
    }) {
        super();
        this.species = species;
        this.selected = selected;
        this.animalId = animalId;
        this.special = getSpecial(this.species.special);
    }

    handleSelect (ev) {
        emitter.trigger('animal:species:update', { speciesId: this.species.id, animalId: this.animalId });
    }

    render () {
        return html`<li class="list-group-item" @click=${this.handleSelect}>
            <h4>${this.species.name}</h4>
            <div>Cost: $${this.species.cost}</div>
            <dl class="attributes">
                <dt>Iron</dt>
                <dd>-</dd>
                <dt>Brawl</dt>
                <dd>${this.species.brawl}</dd>
                <dt>Grit</dt>
                <dd>${this.species.grit}</dd>
                <dt>Luck</dt>
                <dd>${this.species.luck}</dd>
                <dt>Move</dt>
                <dd>${this.species.move}</dd>
            </dl>
        </li>`;
    }
}

if (!window.customElements.get('dbl-species-details')) {
    window.customElements.define('dbl-species-details', SpeciesDetails);
}
