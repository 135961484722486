export default class Species {
    id = 0;
    name = '';
    cost = 0;
    brawl = 0;
    grit = 0;
    luck = 0;
    move = 0;

    constructor ({
        id = 0,
        name = '',
        cost = 0,
        brawl = 0,
        grit = 0,
        luck = 0,
        move = 0
    }) {
        this.id = id;
        this.name = name;
        this.cost = cost;
        this.brawl = brawl;
        this.grit = grit;
        this.luck = luck;
        this.move = move;
    }
}
