import { html } from 'lit';
import KnicknackDetails from '../components/KnicknackDetails.js';
import BaseElement from './BaseElement.js';
import { getKnicknacks } from '../services/KnicknackService.js';

export default class KnicknackList extends BaseElement {
    constructor ({ charId = 0 }) {
        super();
        this.charId = charId;
    }

    close () {
        this.getRootNode().host?.clearColumns(false, true);
    }

    render () {
        return html`<div class="d-flex justify-content-between align-items-center mb-3">
        <h3>Choose Knicknack</h3>
        <button type="button" class="btn btn-secondary btn-sm" @click=${this.close}>Close</button>
        </div>
        <p>Note: Knicknakcs are found during/after a game not purchased.</p>
        <ul class="list-group">
            ${getKnicknacks().map((knicknack) => {
        return new KnicknackDetails({ knicknack, charId: this.charId });
    })}
        `;
    }
}

if (!window.customElements.get('dbl-knicknack-list')) {
    window.customElements.define('dbl-knicknack-list', KnicknackList);
}
